import {
  getElementFromSelector,
  isVisible,
  isElement,
  getConditions,
} from './util/index'


import Dialog from './dialog'
import EventHandler from './dom/event-handler'
import Manipulator from './dom/manipulator'
import Help from './help'
import SelectorEngine from './dom/selector-engine'
import { isMobile, getPlaceholder } from './util'

const DATA_KEY = 'ff.occattributes'
const DATA_API_KEY = '.data-api'
const EVENT_KEY = `.${DATA_KEY}`

const EVENT_CHANGE = `change${EVENT_KEY}`
const EVENT_CLICK = `click${EVENT_KEY}`
const EVENT_SUBMIT = `submit${EVENT_KEY}`
const EVENT_CLICK_DATA_API = `click${EVENT_KEY}${DATA_API_KEY}`
const EVENT_LOAD_DATA_API = `load${EVENT_KEY}${DATA_API_KEY}`
const SELECTOR_DATA_TOGGLE = '[data-ff-target="guest"]'

const Default = {
  ate: 1,
  lunchbox: 2,
  not: 3,
  out: 4,
  delivery: 5
}

class OccAttributes {
  constructor(config) {
    this.config = config
    if (!window.hasOwnProperty('ff')) window.ff = {}
    this.helpTopic = 'occasion_attributes'
    this.dayOverview = window.ff.pages.day
    this.persons = this.config.persons
    this.attendance = this.config.attendance
    this.wfh = this.config.wfh
    this.guests = 0
    this.answers = this.config.answers
    this.questions = this.config.questions
    this.attendanceQuestion = this.config.attendance_question
    this.currentQuestion = ''
    this.nextQuestion = ''
    this.values = {
      ate: Default.ate,
      lunchbox: Default.lunchbox,
      not: Default.not,
      out: Default.out,
      delivery: Default.delivery
    }
    if (this.config.ate) this.values.ate = Number.parseInt(this.config.ate, 10)
    if (this.config.lunchbox) this.values.lunchbox = Number.parseInt(this.config.lunchbox, 10)
    if (this.config.not) this.values.not = Number.parseInt(this.config.not, 10)
    if (this.config.out) this.values.out = Number.parseInt(this.config.out, 10)
    if (this.config.delivery) this.values.delivery = Number.parseInt(this.config.delivery, 10)
    this.el = {}
    this.el.that = this
    this.el.attendanceInputs = document.querySelectorAll('#attendance input[type=radio][id^="per_"]')
    this.el.attendanceTimeLabel = document.querySelectorAll('#attendance .meal_att_column.timelabel')[0]
    this.el.attendanceTime = document.querySelectorAll('#attendance select[name^="attendance_time"]')
    this.el.attendanceSelects = document.querySelectorAll('#attendance select[id^="per_"]')
    this.el.guestsInputs = document.querySelectorAll('#attendance #guests input, #attendance #guests select[id^="per_"]')
    this.el.inputs = document.querySelectorAll('#f1 input, #f1 select, #f1 textarea')
    this.el.guestsTime = document.querySelectorAll('#attendance #guests select[name^="attendance_time"]')
    this.el.guestCount = document.getElementById('guest_count')
    this.el.guestsText = document.getElementById('guests_text')
    this.el.form = document.getElementById('f1')
    this.el.allAte = document.getElementById('all_ate')
    this.el.attributesTable = document.getElementById('attribute_questions')
    this.el.attendanceTable = document.getElementById('attendance')
    this.el.placeholders = document.querySelectorAll('[data-hideif]')
    this.timedAttendance = [ Default.ate, Default.lunchbox ]
    this.el.mobileStates = document.querySelectorAll('#attribute_questions .question-group:not(.hidden-if)')
    this.el.mobileState = 0
    if (this.config.ooh >= 1) {
      document.querySelector('body').classList.add('ooh')
      this.el.mobileState = 1
      if ( this.el.mobileStates.length > 1 ) {
        for (const page of [...this.el.mobileStates]) {
          page.hide()
        }
      }
      if (isMobile) {
        // Get the element
        const elem = document.querySelector('.side-days')
        // Create a copy of it
        const clone = elem.cloneNode(true)
        elem.hide()
        clone.classList.add('clone')
        // Inject it into the DOM
        document.querySelector('header.header .nav').after(clone)
      }
      if (this.el.mobileStates.length) {
        this._showPageState()
        this._setupMobileFlippers()
      }
    } 
    if (isMobile) {
      document.body.classList.add('mobile')
      this.el.mobileStates = document.querySelectorAll('.question-group')
      this.el.mobileState = 1
      if ( this.el.mobileStates.length > 1 ) {
        for (const page of [...this.el.mobileStates]) {
          page.classList.add('hidden')
        }
      }
      // Get the element
      const elem = document.querySelector('.side-days')
      // Create a copy of it
      const clone = elem.cloneNode(true)
      elem.hide()
      clone.classList.add('clone')
      // Inject it into the DOM
      document.querySelector('header.header .nav').after(clone)
      if (this.el.mobileStates.length > 1) {
        this._showPageState()
        this._setupMobileFlippers()
      }
    }

    //for (const input of this.el.inputs) {
    this.checkBlockCondtions()
    //}
    window.addEventListener("keypress",function(e) { 
      if (e.target.tagName, e.which === 13) {
        e.preventDefault()
      }
    })
    EventHandler.on(this.el.form, EVENT_SUBMIT, event => {
      return this.validateMealForm(event)
    })
    EventHandler.on(this.el.allAte, EVENT_CHANGE, () => {
      this.checkRadioButtons()
    })
    EventHandler.on(this.el.inputs, EVENT_CHANGE, event => {
      this.checkRadioConditions(event.target)
    })
    EventHandler.on(document, EVENT_CLICK_DATA_API, SELECTOR_DATA_TOGGLE, event => {
      const trgt = event.target

      if (trgt.tagName === 'A' || (trgt.parentNode && trgt.parentNode.tagName === 'A') || trgt.tagName === 'AREA') {
        event.preventDefault()
      }

      const config = {
        ...Manipulator.getDataAttributes(trgt)
      }
      if (config.toggle === 'subtract') {
        this.subGuest()
      } else {
        this.addGuest()
      }
    })
    if (document.body.classList.contains('mobile')) {
      this._copyAttendeeRadioToSelect()
      for (const ateSelects of this.el.attendanceSelects) {
        EventHandler.on(ateSelects, EVENT_CHANGE, event => {
          // use name - assumes 1-n for attendees and 25 for guests
          const selectId = event.target.name
          const selectValue = event.target.value
          const r = /\d+/
          const personId = Number.parseInt(selectId.match(r), 10)
          document.querySelector('#attendance input[type=radio][id^="per_' + personId + '_att_' + selectValue + '"]').checked = true
          this._checkAte()
          if (event.target.value > this.values.lunchbox) {
            this._uncheckAllAte()
          }

          this._checkAttendance(personId, event.target)
        })
      }
    } else {
      for (const ateRadio of this.el.attendanceInputs) {
        EventHandler.on(ateRadio, EVENT_CLICK, event => {
          // use name - assumes 1-n for attendees and 25 for guests
          const radioId = event.target.name
          const r = /\d+/
          const personId = Number.parseInt(radioId.match(r), 10)
          this._checkAte()
          if (event.target.value > this.values.lunchbox) {
            this._uncheckAllAte()
          }

          this._checkAttendance(personId, event.target)
        })
      }
    }

    for (const timeSelect of this.el.attendanceTime) {
      EventHandler.on(timeSelect, EVENT_CLICK, event => {
        this._changeTime(event.target)
      })
      EventHandler.on(timeSelect, EVENT_CHANGE, event => {
        this._changeTime(event.target)
      })
    }

    this.el.attributesInputs = document.querySelectorAll('#attendance input, #attendance select, #attribute_questions input, #attribute_questions select')
    this.updateGuestsText()
    this._checkAte()
    Help.init({ topic: this.helpTopic })
  }

  // Getters

  static get Default() {
    return Default
  }

  static get DATA_KEY() {
    return DATA_KEY
  }

  _copyAttendeeRadioToSelect() {
    for (const select of this.el.attendanceSelects) {
      while (select.options.length > 0) {
        select.remove(0)
      }

      let unselectedText = '---'
      if (select.dataset.placeholder) {
        unselectedText = select.dataset.placeholder
      }
      if (select.hasAttribute('placeholder')) {
        unselectedText = select.getAttribute('placeholder')
      }

      const option = document.createElement('option')
      option.text = unselectedText
      option.value = ''
      select.add(option)
      if (document.body.classList.contains('mobile')) {
        select.disabled = false
      }
    }

    for (const attendeeRadio of [...this.el.attendanceInputs]) {
      const thisId = attendeeRadio.id
      const opt = document.createElement('option')
      opt.text = attendeeRadio.previousElementSibling.textContent
      opt.value = attendeeRadio.value
      opt.selected = attendeeRadio.checked
      if (document.body.classList.contains('mobile')) {
        attendeeRadio.disabled = false
      }

      const selId = thisId.replace('_' + thisId.replace(/^(?:[^_]*_){2}/g, ''), '')
      document.getElementById(selId).add(opt)
    }
  }

  checkBlockCondtions() {
    const answers = (typeof this.answers === 'object') ? this.answers : JSON.parse(this.answers)
    this.answers = answers
    const answerKeys = Object.keys(this.answers)
    for (const placeholder of [...this.el.placeholders]) {
      
      let expr = placeholder.dataset.hideif
      //console.log('or '+ expr)
      let hideThis = false
      if (expr.length !== 0 && !expr.startsWith('m_') && !expr.startsWith('md_')){
        expr = getConditions(answers, expr, answerKeys)
        try{
          hideThis = eval(expr)
          //console.log((hideThis ? 'hide' : 'show') +' '+ placeholder.nodeName +' '+ placeholder.dataset.hideif +' '+ expr )
          if (hideThis) {
            if (!placeholder.classList.contains('hidden-if')){
              placeholder.hideif()
            }
          }  
          if (!hideThis) {
            if (placeholder.classList.contains('hidden-if')){
              placeholder.showif() 
            }
          }   
        } catch (error) {
          console.log(expr +' has error '+ error.message)
        }
      }
      
    }
  }

  checkRadioConditions(input) {
    const answers = (typeof this.answers === 'object') ? this.answers : JSON.parse(this.answers)

    if ("ffNext_external_id" in input.dataset && input.checked && input.dataset.ffNext_external_id != '' && input.dataset.ffNext_external_id != this.currentQuestion) {
      this.nextQuestion = input.dataset.ffNext_external_id
    }    
    this.answers = answers
    if ("placeholder" in input.dataset) {
      this.answers[input.dataset.placeholder].selected.length = 0
      let parent = SelectorEngine.parents(input, 'div')
      let exclBox = parent[0].querySelector('input[data-exclusive="1"]:checked')
      if (Number.parseInt(input.dataset.exclusive, 10) !== 1 && exclBox != null) {
        exclBox.checked = false
      }
      //must test after to exclude swapping from exclusive
      let checkedBoxes = parent[0].querySelectorAll('input:checked')
      if ("exclusive" in input.dataset && Number.parseInt(input.dataset.exclusive, 10) === 1) {
        this.answers[input.dataset.placeholder].selected.length = 0
        checkedBoxes.forEach(el => el.checked = false);
        input.checked = true
      } 
      checkedBoxes = parent[0].querySelectorAll('input:checked')
      checkedBoxes.forEach(el => this.answers[input.dataset.placeholder].selected.push(el.value));
      
      /*
      for (const excl of [...exclBoxes]) {
        for (const chkBox of [...checkedBoxes]) {
          if (chkBox == excl && excl.checked)  excl.checked = false
        }
      } 
      */ 
      const answerKeys = Object.keys(this.answers)
      for (const placeholder of [...this.el.placeholders]) {
        let expr = placeholder.dataset.hideif
        //console.log('or '+ expr)
        let hideThis = false
        
        if (expr.length !== 0 && !expr.startsWith('m_') && !expr.startsWith('md_')){
          //expr = expr.replaceAll(/and/ig, "&&").replaceAll(/or/ig, "||").replaceAll(' ', "")
          expr = getConditions(answers, expr, answerKeys)
          
          try{
            hideThis = eval(expr)
            console.log((hideThis ? 'hide' : 'show') +' '+ placeholder.nodeName +' '+ placeholder.dataset.hideif +' '+ expr )
            //console.log(hideThis +' '+ placeholder.dataset.hideif +' '+ expr )
            if (hideThis) {
              if (!placeholder.classList.contains('hidden-if')){
                placeholder.hideif()  
                this.el.mobileStates = document.querySelectorAll('#attribute_questions .question-group:not(.hidden-if)')
                if (this.el.mobileState >= this.el.mobileStates.length) {

                  const paddles = document.querySelectorAll('.button-cont [id*="_clone"]')
                  const submits = document.querySelectorAll('.button-cont .hidden')

                  this._NodeListforEach(paddles, (index, paddle) => {
                    if (paddle instanceof Element) {
                      paddle.hide()
                    }
                  })

                  this._NodeListforEach(submits, (index, submit) => {
                    if (submit instanceof Element) {
                      submit.show()
                    }
                  })
                }
              }
            }  
            if (!hideThis) {
              if (placeholder.classList.contains('hidden-if')){
                placeholder.showif() 
              }
            }  
            
          } catch (error) {
            console.log(expr +' has error '+ error.message)
          }
         
        }
        
      }  
    }
    const ateVals = this.countAteValues()

    this.el.mobileStates = document.querySelectorAll('#attribute_questions .question-group:not(.hidden-if)')
    let curPage = document.querySelector('#attribute_questions .question-group:not(.hidden):not(.hidden-if)')
    let countStates = this.el.mobileStates.length
    let afterCurrent = false
    let afterStates = false
    for (const state of this.el.mobileStates) {
      if (afterCurrent == true && state.classList.contains('hidden')) {
        afterStates = true
      } 
      if (curPage == state) {
        afterCurrent = true
      }  
      if (state.classList.contains('hidden-if')) {
        countStates--;
      }
    } 
    //console.log('_showPageState '+ this.el.mobileState +' '+ countStates +' '+ ateVals.ate +' '+afterStates)
    if ((afterStates)) {
      const paddles = document.querySelectorAll('.button-cont [id*="_clone"].hidden')
      const submits = document.querySelectorAll('.button-cont a:not(.hidden), .button-cont button:not(.hidden)')

      if (paddles.length > 0) {
        this._NodeListforEach(paddles, (index, paddle) => {
          if (paddle instanceof Element) {
            paddle.show()
          }
        })
        this._NodeListforEach(submits, (index, submit) => {
          if (submit instanceof Element) {
            submit.hide()
          }
        })
      }
    }
  }

  updateGuestsText() {
    if (this.el.guestsText){
      let guesttxt = ''
      switch (Number(this.el.guestCount.value)) {
        case 0:
          for (const inp of this.el.guestsInputs) {
            SelectorEngine.parents(inp, 'li')[0].hide()
          }

          if (this.el.attendanceTime.length > 0) {
            for (const timeSelect of this.el.guestsTime) {
              timeSelect.hide()
            }
          }

          guesttxt = getPlaceholder('diary_js_add_guests')
          break
        case 1:
          for (const inp of this.el.guestsInputs) {
            SelectorEngine.parents(inp, 'li')[0].show()
          }

          if (this.el.guestsTime.length > 0) {
            for (const timeSelect of this.el.guestsTime) {
              timeSelect.show()
            }
          }

          guesttxt = this.el.guestCount.value + '&nbsp;' + getPlaceholder('diary_js_guest')
          break
        default:
          for (const inp of this.el.guestsInputs) {
            SelectorEngine.parents(inp, 'li')[0].show()
          }

          if (this.el.guestsTime.length > 0) {
            for (const timeSelect of this.el.guestsTime) {
              timeSelect.show()
            }
          }

          guesttxt = this.el.guestCount.value + '&nbsp;' + getPlaceholder('diary_js_guests')
          break
      }

      this.el.guestsText.innerHTML = guesttxt
    }
  }

  addGuest() {
    this.el.guestCount.value++
    this.updateGuestsText()
    this._checkAte()
  }

  subGuest() {
    if (this.el.guestCount.value < 1) {
      return false
    }

    this.el.guestCount.value--
    this.updateGuestsText()
    this._checkAte()
  }

  _NodeListforEach(array, callback, scope) {
    for (let i = 0; i < array.length; i++) {
      callback.call(scope, i, array[i]); // passes back stuff we need
    }
  }

  _flipPage(e) {
    const targ = (e.target.hasAttribute('title') ? e.target : e.target.closest("[title]"))
    //if (targ instanceof Element) {
    
    let cnt = 0;

    let BreakException = {}
    if (targ.classList.contains('prevBtn')) {
        
        //try {
        //  this.el.mobileStates.slice().reverse().forEach(function (value, i) {
        //    this.el.mobileState = i
        //    if (!value.classList.contains('hidden-if')) {
        //      throw BreakException
        //    } 
        //  })
        //} catch (e) {}
        for (const state of this.el.mobileStates) {
          cnt++
          if (!state.classList.contains('hidden')) {
            this.el.mobileState = cnt
            console.log('ms set'+ cnt)
          }
          state.hide()
        } 
        
        for (let i = this.el.mobileState - 1; i >= 0 ; i--) {
          let state = this.el.mobileStates[i]
          this.el.mobileState = i
          if (!state.classList.contains('hidden-if')) {
            break;
          }  
        }

        if (this.el.mobileState === 0) {
          const vback = document.querySelector('.button-cont a[href^="http"]').getAttribute('href')
          window.location.href = vback
        }
      } else if (targ.classList.contains('nextBtn')) {
        const formOK = this.validateMealForm(e)
        if (!formOK) {
          return false
        }

        for (const state of this.el.mobileStates) {
          cnt++
          if (!state.classList.contains('hidden')) {
            this.el.mobileState = cnt
            console.log('ms set'+ cnt)
          }
          state.hide()
        }  
        //try {
        //  this.el.mobileStates.forEach(function (value, i) {
        //    this.el.mobileState = i
        //    if (!value.classList.contains('hidden-if')) {
        //      throw BreakException
        //    } 
        //  })
        //} catch (e) {}
        
        console.log('bf'+this.el.mobileState)
        for (let i = Number.parseInt(this.el.mobileState, 10) + 1; i <= Number.parseInt(this.el.mobileStates.length, 10) ; i++) {
          let state = this.el.mobileStates[i]
          let elements = document.querySelectorAll('#attribute_questions .question-group:not(.hidden-if)')
          let getEl = elements.item(i - 1)
          if (getEl) {
            console.log(i +' '+ getEl.dataset.placeholder +' '+ getEl.classList )
            this.el.mobileState = i
            if (getEl.classList.contains('hidden')) {
              break;
            }
          }    
        }
        console.log('af'+this.el.mobileState)
      }
      this._showPageState()
    //}
  }

  _setupMobileFlippers() {
    const clones = document.querySelectorAll('.button-cont [id*=_clone]')
    if ((isMobile || this.config.ooh >= 1) && this.el.mobileStates.length > 1 && clones.length == 0) {
      const nodes = document.querySelectorAll('.button-cont .continue1')
      for (const node of [...nodes]) {
        const clone = node.cloneNode(true)
        node.classList.add('hidden')
        clone.id += '_clone'

        if (clone.hasAttribute('href')) {
          clone.removeAttribute('href')
          clone.classList.add('prevBtn')
        }

        if (clone.hasAttribute('type')) {
          clone.setAttribute('type', 'button')
          clone.classList.add('nextBtn')
        }

        EventHandler.on(clone, EVENT_CLICK, event => {
          this._flipPage(event)
        })
        document.querySelector('.button-cont').append(clone)
      }
    }
  }

  _showPageState() {
    if (this.el.attributesTable) this.el.attributesTable.show()
    if (isMobile || this.config.ooh >= 1) {
      let curCount = 0
      this.el.mobileStates = document.querySelectorAll('#attribute_questions .question-group:not(.hidden-if)')

      for (const page of [...this.el.mobileStates])  {
        curCount++
        let newPage = page
        //console.log('_showPageState'+this.el.mobileState +' '+ curCount)
        if (curCount === Number.parseInt(this.el.mobileState, 10)) {
          //console.log('_showPageState '+newPage.dataset.placeholder)
          /*
          if (this.nextQuestion != '' && newPage.getAttribute('data-ff-next_external_id') != this.nextQuestion){
            newPage = document.querySelector('#attribute_questions .question-group[data-ff-external_id="'+ this.nextQuestion +'"]')
            if (newPage.classList.contains('hidden-if')){
              newPage = document.querySelector('#attribute_questions .question-group[data-ff-external_id="'+ newPage.getAttribute('data-ff-next_external_id') +'"]')
            }
          }
          */
          newPage.show()
          if (isVisible(newPage)) {
            if (newPage.hasAttribute('data-ff-external_id')) {
              this.currentQuestion = newPage.getAttribute('data-ff-external_id')
            }  
            if (newPage.hasAttribute('data-ff-next_external_id')) {
              this.nextQuestion = newPage.getAttribute('data-ff-next_external_id')
            }    
          }

          const ateVals = this.countAteValues()

          this.el.mobileStates = document.querySelectorAll('#attribute_questions .question-group:not(.hidden-if)')
          let countStates = this.el.mobileStates.length
          let afterCurrent = false
          let afterStates = false
          for (const state of this.el.mobileStates) {
            if (afterCurrent == true && state.classList.contains('hidden')) {
              afterStates = true
            } 
            if (newPage == state) {
              afterCurrent = true
            }  
            if (state.classList.contains('hidden-if')) {
              countStates--;
            }
          } 
          //console.log('_showPageState '+ this.el.mobileState +' '+ countStates +' '+ ateVals.ate +' '+afterStates)
          if ((!afterStates && (this.el.mobileState >= countStates)) || (Number.parseInt(ateVals.ate, 10) === 0)) {
            const paddles = document.querySelectorAll('.button-cont [id*="_clone"]')
            const submits = document.querySelectorAll('.button-cont .hidden')

            this._NodeListforEach(paddles, (index, paddle) => {
              if (paddle instanceof Element) {
                paddle.hide()
              }
            })

            this._NodeListforEach(submits, (index, submit) => {
              if (submit instanceof Element) {
                submit.show()
              }
            })

            if (Number.parseInt(ateVals.ate, 10) === 0) {
              document.querySelector('.button-cont button[type="submit"]').click()
            }
          }      
        } 
      }
    }
  }

  _checkAte() {
    const ateVals = this.countAteValues()
    let NumEat = 0;

    NumEat = Number.parseInt(ateVals.ate, 10)

    if (NumEat == 0 && this.el.attendanceTable) {
      if (this.el.attendanceTime.length > 0) {
        for (const timeSelect of this.el.attendanceTime) {
          timeSelect.parentElement.hide()
        }
      }

      if (this.el.attributesTable) this.el.attributesTable.hide()
    } else {
      /*
      if (this.el.attendanceTime.length > 0) {
        for (const timeSelect of this.el.attendanceTime) {
          timeSelect.hide()
        }
      }
      */
      //this._showPageState()

      for (const attendees of this.el.attendanceInputs) {
        const checkbox = document.getElementById(attendees.id)
        if (checkbox && checkbox.checked === true) {
          const ateVal = Number.parseInt(checkbox.value, 10)
          const matches = checkbox.name.match(/\[(.*?)\]/)
          let submatch = false
          if (matches) {
            submatch = matches[1]
          }

          if (!this.timedAttendance.includes(ateVal) &&
            this.el.attendanceTime.length > 0 && submatch) {
            document.getElementById('attendance_time_' + submatch).parentElement.hide()
          }
        }
      }
    }

    if (document.body.classList.contains('mobile')) {
      this._copyAttendeeRadioToSelect()
    }

    return ateVals.ate
  }

  _checkAttendance(personId, element) {
    if (this.el.attendanceTime.length <= 0) {
      return true
    }

    if (this.attendance &&
       this.attendance[personId] &&
        ( this.timedAttendance.includes(parseInt(this.attendance[personId].attendance, 10)) ) &&
          (!this.timedAttendance.includes(parseInt(element.value, 10)))) {
      Dialog.confirm({
        content: getPlaceholder('diary_js_confirm_override_whw'),
        buttons: [{
          label: getPlaceholder('diary_js_ok'),
          action() {
            element.checked = true
            if (document.querySelector('attendance_time_' + personId)) {
              document.querySelector('attendance_time_' + personId).parentElement.hide()
            }

            return true
          }
        }]
      })
      return false
    }

    if (this.timedAttendance.includes(parseInt(element.value, 10)) && this.el.attendanceTime.length > 0) {
      document.getElementById('attendance_time_' + personId).parentElement.show()
      document.getElementById('attendance_time_' + personId).focus()
    } else {
      document.getElementById('attendance_time_' + personId).parentElement.hide()
    }

    return true
  }

  _changeTime(element) {
    if (element.value == '') {
      return
    }

    for (const timeVal of this.el.attendanceTime) {
      if (timeVal.value == '') {
        timeVal.value = element.value
      }
    }
  }

  getAtebox(timeEl) {
    return document.querySelector(timeEl.parentNode.parentNode + ' input[value=' + this.values.ate + ']')
  }

  checkRadioButtons() {
    if (!this.el.allAte.checked) {
      return false
    }

    const radios = this.el.attendanceInputs
    for (const atTimeRadio of radios) {
      if (Number.parseInt(atTimeRadio.value, 10) === Number.parseInt(Default.ate, 10)) {
        atTimeRadio.checked = true
      }
    }

    this._checkAte()

    if (this.el.attendanceTime.length > 0) {
      this.el.attendanceTimeLabel.show()
      for (const timeSelect of this.el.attendanceTime) {
        timeSelect.parentElement.show()
      }

      if (Number.parseInt(this.el.guestCount.value, 10) === 0) {
        for (const timeSelect of this.el.guestsTime) {
          timeSelect.hide()
        }
      }
    }
  }

  _uncheckAllAte() {
    const guestIndex = 'per_' + this.persons + '_att_' + Default.ate
    if (this.el.guestCount > 0 && document.getElementById(guestIndex).checked === true) {
      return false
    }

    if (this.el.allAte) this.el.allAte.checked = false
  }

  countAteValues() {
    let dideat = 0
    let didEatOut = 0
    let didntEatAtAll = 0
    const guestInputs = [...this.el.guestsInputs]

    const answers = (typeof this.answers === 'object') ? this.answers : JSON.parse(this.answers)

    this.answers = answers
    if (this.el.attendanceInputs.length ==0 && this.answers['attendance'].selected.length != 0){
      for (const ans of this.answers['attendance'].selected) {
        switch (Number.parseInt(ans, 10)) {
          case this.values.ate:
          case this.values.lunchbox:
          case this.values.delivery:
          case this.values.out:        
            dideat++
            break
          case this.values.not:
            didntEatAtAll++
            break
          default:
            break
        }
      }
    }  
    for (const inp of this.el.attendanceInputs) {
      const checkbox = document.getElementById(inp.id)
      if (Boolean(guestInputs.find((node, id) => node.id === inp.id)) &&
        Number.parseInt(this.el.guestCount.value, 10) <= 0) {
      } else if (checkbox && checkbox.checked === true) {
        switch (Number.parseInt(checkbox.value, 10)) {
          case this.values.ate:
          case this.values.lunchbox:
          case this.values.delivery:    
          case this.values.out:
            dideat++
            break
          case this.values.not:
            didntEatAtAll++
            break
          default:
            break
        }
      }
    }

    return {
      ate: dideat,
      out: didEatOut,
      not: didntEatAtAll
    }
  }

  validateMealForm(event) {
    let errMsg = ''
    const ate = this.countAteValues()
    const persons = this.persons + (this.el.guestCount.value > 0 && this.config.ooh < 1 ? 1 : 0)
    const unanswered = (typeof this.questions === 'Object') ? this.questions : JSON.parse(this.questions)

    for (const y in unanswered) {
      if (unanswered[y]['type']=='PAG') {
        delete unanswered[y];
      } 
    }

    if (this.el.attendanceTable ) {
      if (Number.parseInt(ate.not, 10) === Number.parseInt(persons, 10)) {
        if (this.config.ooh >= 1 ) {
          errMsg += getPlaceholder('diary_js_didnoteat') + '<br />'
        } else this.el.form.action = this.config.day_overview
      } else if (Number.parseInt(ate.ate + ate.not, 10) != Number.parseInt(persons, 10)) {
        errMsg += getPlaceholder('diary_js_message_complete_entries') + ' "' + this.config.attendance_description + '"<br />'
      }
    }

    if (ate.ate > 0) {
      if (this.el.attendanceTime.length > 0) {
        for (let y = 0; y < this.el.attendanceTime.length - (this.el.guestCount.value > 0 ? 0 : 1); y++) {
          const checkbox = document.querySelector('#attendance input:checked[type=radio][id^=\'per_' + y + '\']')
          if (this.el.attendanceTime[y].value == '' &&
						checkbox && this.timedAttendance.includes(Number(checkbox.value) )) {
            errMsg += getPlaceholder('diary_js_message_attendance_time_missing') + '<br />'
            break
          }
        }
      }
    }
     
    for (const thisel of this.el.attributesInputs) {
      switch (thisel.type) {
        case 'hidden':
          if (thisel.hasAttribute('data-ff-external_id')) {
            delete unanswered[Manipulator.getDataAttribute(thisel, ('external_id'))]
          }

          break
        case 'select':
        case 'select-one':
          if (thisel.selectedIndex > 0) {
            delete unanswered[thisel.id]
          }

          break
        case 'radio':
        case 'checkbox':
        default:
          if (thisel.checked == true) {
            if (thisel.hasAttribute('data-ff-next_external_id') 
              && thisel.hasAttribute('data-ff-external_id')
              && Manipulator.getDataAttribute(thisel, 'next_external_id') !== null
              && Manipulator.getDataAttribute(thisel, 'next_external_id') != Manipulator.getDataAttribute(thisel, ('external_id'))) {
              delete unanswered[Manipulator.getDataAttribute(thisel, ('next_external_id'))]
              delete unanswered[Manipulator.getDataAttribute(thisel, ('external_id'))]
            } else if (thisel.hasAttribute('data-ff-external_id')) {
              delete unanswered[Manipulator.getDataAttribute(thisel, ('external_id'))]
            } else if (thisel.hasAttribute('questionData')) {
              try {
                const params = JSON.parse(thisel.hasAttribute('questionData'))
                delete unanswered[params.external_id]
              // eslint-disable-next-line no-unused-vars
              } catch (_error) {}
            } else {
              delete unanswered[thisel.id]
            }

            break
          }
      }
    }

    if (Object.keys(unanswered).length) {
      // eslint-disable-next-line guard-for-in
      for (const i in unanswered) {
        if (isMobile || this.config.ooh >= 1) {
          const firstThisInp = document.querySelector('[data-ff-external_id="' + i + '"]')
          if (firstThisInp !== null) {
            let panelPage = firstThisInp
            if (!panelPage.classList.contains('question-group')) {
              panelPage = SelectorEngine.parents(firstThisInp, 'div.question-group')
            }
            if (panelPage &&
                !panelPage.classList.contains('hidden') &&
                !panelPage.classList.contains('hidden-if') &&
                unanswered[i] && unanswered[i].description !== undefined) {
              errMsg += getPlaceholder('diary_js_message_complete_entries') + ' "' + unanswered[i].description + '"<br />'
              break
            }
          } else {console.log(i + ' missing')}
        } else {
          if (unanswered[i] &&
              unanswered[i].description &&
              Object.keys(unanswered[i]).length &&
                unanswered[i].description !== undefined) {
            errMsg += getPlaceholder('diary_js_message_complete_entries') + ' "' + unanswered[i].description + '"<br />'
          }
        }
      }
    }
    

    if (errMsg !== '') {
      event.preventDefault()
      Dialog.alert(this.el.form, { content: errMsg })
      return false
    }

    // this.el.form.submit();
    return true
  }
}

export default OccAttributes

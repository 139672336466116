
import Dialog from './dialog'
import EventHandler from './dom/event-handler'
import Manipulator from './dom/manipulator'
import SelectorEngine from './dom/selector-engine'
import { isMobile, getPlaceholder, getConditions } from './util'

const NAME = 'needs'
const DATA_KEY = 'ff.needs'
const EVENT_KEY = `.${DATA_KEY}`
const DATA_API_KEY = '.data-api'

const EVENT_CHANGE = `change${EVENT_KEY}`
const EVENT_CLICK = `click${EVENT_KEY}`
const EVENT_CLICK_DATA_API = `click${EVENT_KEY}${DATA_API_KEY}`

const SELECTOR_DATA_TOGGLE = '[data-ff-toggle="needs"]'

class Needs {
  constructor() {
    if (!window.hasOwnProperty('ff')) window.ff = {}
    window.ff.currentNeedsQ = 1
    this.close = true
    this.questionContainer = null
    this.currentItem = null
    this.currentTab = null
    this.answerContainer = null
    this.itemContainer = null
    this._dialog = {}
  }

  static get Default() {
    return Default
  }

  static get DATA_KEY() {
    return DATA_KEY
  }

  static checkRadioConditions(input) {
    const answers = (typeof window.ff.answers === 'object') ? window.ff.answers : JSON.parse(window.ff.answers)
    const question = SelectorEngine.parents(input, 'div[id^=questionpopup]').pop();
    let hideifChanges = false;

    if (question.dataset && "placeholder" in question.dataset) {
      for (const placeholder of [...document.querySelectorAll('.modal-body [data-hideif]')]) {
        if (
          placeholder.dataset.hideif.includes(question.dataset.placeholder) &&
          "level" in placeholder.dataset &&
          placeholder.dataset.level === 'product'
        ) {
          const product = SelectorEngine.parents(input, 'div.answers[id^=product]').pop();
          placeholder.showif();

          const hasEqualQuanti = placeholder.dataset.hideif.replaceAll(' ', '') === `${question.dataset.placeholder}=${input.value}`;
          const showAnswerDiv = placeholder.querySelectorAll(`div.answers .answers`);
          let totalAnswers = showAnswerDiv.length;

          showAnswerDiv.forEach(div => {
            if (div.id.includes('dish')) {
              totalAnswers--;
            }

            if (div.id !== product.id) {
              div.classList.add('hidden');
              return;
            }

            const coursesHeader = placeholder.querySelectorAll('div.items .courses > li')
            coursesHeader.forEach(courseHeader => courseHeader.classList.remove('hidden'))

            div.classList.remove('hidden', 'hidden-hideif');
            const inputs = div.querySelectorAll('input');
            inputs.forEach(input => input.removeAttribute('disabled'));

            const tabs = placeholder.querySelectorAll(`li[id=${product.id}]`);
            tabs.forEach(tab => tab.classList.remove('hidden', 'hidden-hideif', 'selected'));

            // Applies the needed classes for the hideif
            if (hasEqualQuanti && div.id === product.id) {
              div.classList.add('hidden', 'hidden-hideif');
              // console.log('inputs to be disabled', inputs);
              inputs.forEach(input => input.setAttribute('disabled', true));
              // console.log('tabs to be hidden', tabs);
              tabs.forEach(tab => tab.classList.add('hidden', 'hidden-hideif'));
              totalAnswers--;
              return;
            }

            div.classList.add('hidden');
          });

          const usageCourses = placeholder.querySelectorAll('div.items .courses [id^=usage_course]')
          usageCourses.forEach(usageCourse => {
            const productsTabs = usageCourse.querySelectorAll('.products li')
            let totalTabs = productsTabs.length;

            const totalHiddenTabs = Array.from(productsTabs).filter(li => li.classList.contains('hidden')).length

            if (totalTabs === totalHiddenTabs) {
              const courseToHide = SelectorEngine.parents(usageCourse, 'li').pop()
              if (courseToHide) {
                courseToHide.classList.add('hidden')
              }
            }
          })

          if (!hideifChanges) {
            hideifChanges = true;
          }
        }
      }
    }

    if (hideifChanges) {
      return;
    }


    if ("placeholder" in input.dataset) {
      if (!window.ff.answers.hasOwnProperty(input.dataset.placeholder)) {
        window.ff.answers[input.dataset.placeholder] = {}
        window.ff.answers[input.dataset.placeholder].selected = []
      }
      window.ff.answers[input.dataset.placeholder].selected.length = 0
      let parent = SelectorEngine.parents(input, 'div')
      let exclBox = parent[0].querySelector('input[data-exclusive="1"]:checked')
      if (Number.parseInt(input.dataset.exclusive, 10) !== 1 && exclBox != null) {
        exclBox.checked = false
      }
      //must test after to exclude swapping from exclusive
      let checkedBoxes = parent[0].querySelectorAll('input:checked')
      if ("exclusive" in input.dataset && Number.parseInt(input.dataset.exclusive, 10) === 1) {
        window.ff.answers[input.dataset.placeholder].selected.length = 0
        checkedBoxes.forEach(el => el.checked = false);
        input.checked = true
      }
      checkedBoxes = parent[0].querySelectorAll('input:checked')
      checkedBoxes.forEach(el => window.ff.answers[input.dataset.placeholder].selected.push(el.value));

      /*
      for (const excl of [...exclBoxes]) {
        for (const chkBox of [...checkedBoxes]) {
          if (chkBox == excl && excl.checked)  excl.checked = false
        }
      }
      */
      const answerKeys = Object.keys(answers)
      for (const placeholder of [...document.querySelectorAll('.modal-body [data-hideif]')]) {
        let expr = placeholder.dataset.hideif
        if (placeholder.dataset.hasOwnProperty('placeholder') && !window.ff.answers.hasOwnProperty(placeholder.dataset.placeholder)) {
          window.ff.answers[placeholder.dataset.placeholder] = {}
          window.ff.answers[placeholder.dataset.placeholder].selected = []
        }
        //console.log('or '+ expr)
        let hideThis = false
        if (expr.length !== 0){
          //expr = expr.replaceAll(/and/ig, "&&").replaceAll(/or/ig, "||").replaceAll(' ', "")
          expr = getConditions(window.ff.answers, expr, answerKeys)

          try{
            hideThis = eval(expr)
            console.log((hideThis ? 'hide' : 'show') +' '+ placeholder.nodeName +' '+ placeholder.dataset.hideif +' '+ expr )
            //console.log(hideThis +' '+ placeholder.dataset.hideif +' '+ expr )
            if (hideThis) {
              if (!placeholder.classList.contains('hidden-if')){
                placeholder.hideif()
                let mobileStates = document.querySelectorAll('.modal-body [class^=popup]:not(.hidden-if)')
                if (mobileState >= mobileStates.length) {

                  const paddles = document.querySelectorAll('.button-cont [id*="_clone"]')
                  const submits = document.querySelectorAll('.button-cont .hidden')

                  this._NodeListforEach(paddles, (index, paddle) => {
                    if (paddle instanceof Element) {
                      paddle.hide()
                    }
                  })

                  this._NodeListforEach(submits, (index, submit) => {
                    if (submit instanceof Element) {
                      submit.show()
                    }
                  })
                }
              }
            }
            if (!hideThis) {
              if (placeholder.classList.contains('hidden-if')){
                placeholder.showif()
              }
            }

          } catch (error) {
            console.log(expr +' has error '+ error.message)
          }
        }
      }
    }
  }

  static openTab(tab) {
    const hasHideifQuantiCondition = this.questionContainer.dataset.hasOwnProperty('hideif') && this.questionContainer.dataset.hideif.includes('quanti')
    // console.log('openTab tab', tab)
    // console.log('openTab hasHideifQuantiCondition', hasHideifQuantiCondition)
    // console.log('openTab currentTab', this.currentTab)
    /*
    if (isMobile) {
      const itemsCrs = this.questionContainer.querySelectorAll('div.items > ul li')
      for (const el of itemsCrs) {
        el.hide()
      }
    }
    */
    if (this.currentTab) {
      let pid = ''

      pid = Object.prototype.hasOwnProperty.call('id', tab) ? tab.id : tab

      if (pid !== this.currentTab.id && !hasHideifQuantiCondition) {
        const currentTab = this.questionContainer.querySelector(`div.answers #${this.currentTab.id}`);
        if (currentTab) {
          currentTab.hide();
        }
      }

      if (!this.currentItem) {
        this.currentItem = this.questionContainer.querySelector('div.items .selected')
      }
      /*
      this.currentItem.classList.remove('selected')
      */
      this.currentTab.hide()
    }

    if (!tab.id) {
      tab = this.questionContainer.querySelector('div.answers #' + tab)
    }

    if (this.currentTab && !this.currentTab.classList.contains('hidden-hideif')) {
      tab.show();
    }

    this.currentItem = this.questionContainer.querySelector(`div.items #${tab.id}:not(.hidden-hideif)`)
    if (this.currentItem !== null) {
      const prevSel = this.questionContainer.querySelectorAll('div.items li.selected')
      for (const el of prevSel) {
        el.classList.remove('selected')
      }

      this.currentItem.classList.add('selected')
      if (isMobile) {
        if (this.currentItem.tagName.toLowerCase() === 'li') {
          const itemCrs = SelectorEngine.parents(this.currentItem, 'li')
          for (const el of itemCrs) {
            el.show()
          }

          const ansPanel = document.querySelector('.answerpanel #' + this.currentItem.id)

          if (ansPanel) {
            ansPanel.show()
          }
          this.currentItem.show()
        } else {
          const itemCrs = SelectorEngine.parents(this.currentItem, 'li')
          itemCrs[0].show()
        }
      }
    }

    this.currentTab = tab
    setTimeout(() => {
      if (this.currentTab && this.currentTab.querySelectorAll('input').length) {
        this.currentTab.querySelector('input').focus()
      }
    }, 150)
  }

  static previousTab() {
    if (this.currentTab && this.currentTab.previousElementSibling) {
      this.openTab(this.currentTab.previousElementSibling.id)
    }
  }

  static nextTab() {
    // console.log('nextTab currentTab', this.currentTab)
    if (this.currentTab) {
      if (this.currentTab.nextElementSibling && this.currentTab.nextElementSibling != this.currentTab ) {
        const question = SelectorEngine.parents(this.currentTab, 'div[id^=questionpopup]').pop();
        // console.log('nextTab question', question)
        let nextSiblingTab = this.currentTab.nextElementSibling
        // console.log('nextTab nextSiblingTab', nextSiblingTab)

        if (question) {
          const hasQuantiPlaceholder = question.dataset.hasOwnProperty('placeholder') &&
              question.dataset.placeholder.includes('quanti');
          const hasProductLevel = question.dataset.hasOwnProperty('level') &&
              question.dataset.level.includes('product');
            // console.log('nextTab hasQuantiPlaceholder', hasQuantiPlaceholder)
            // console.log('nextTab hasProductLevel', hasProductLevel)

          if (hasQuantiPlaceholder || hasProductLevel) {
            while (nextSiblingTab.id.includes('dish') || nextSiblingTab.classList.contains('hidden-hideif')) {
              nextSiblingTab = nextSiblingTab.nextElementSibling;
              // console.log('nextTab nextSiblingTab while loop', nextSiblingTab)
            }
          }
        }

        // console.log('nextTab currentTab.nextElementSibling', nextSiblingTab);
        // console.log('nextTab openTab -> currentTab.nextElementSibling.id', this.currentTab.nextElementSibling.id)
        this.openTab(nextSiblingTab.id)
      } else {
        // console.log('nextTab openTab -> currentTab.id', this.currentTab.id)
        this.openTab(this.currentTab.id)
      }
    } else if (this.currentTab.querySelectorAll('input[type=radio]:checked, input[type=checkbox]:checked').length === 0) {
      const currentDisplay = document.querySelector('[id^="questionpopup_"]:not(.hidden)')
      const form = currentDisplay.closest('form')
      Dialog.alert(form, { content: getPlaceholder('diary_js_invalid_need_question') })
      return false
    }
  }

  static hasattr(el, attr) {
    return typeof el === 'object' && el !== null && 'getAttribute' in el  && el.hasAttribute(attr) ? true : false
  }

  static resetTabs(dir) {
    // console.log('resetTabs',  dir)
    // into tab 2 of needs or later
    let uncompleted = {}
    this.questionContainer = document.getElementById('questionpopup_' + window.ff.currentNeedsQ)
    const hasQuantiPlaceholder = this.questionContainer.dataset.hasOwnProperty('placeholder') && this.questionContainer.dataset.placeholder.includes('quanti')
    const isProductLevel = this.questionContainer.dataset.hasOwnProperty('level') && this.questionContainer.dataset.level.includes('product')

    // console.log('resetTabs hasQuantiPlaceholder', hasQuantiPlaceholder)
    // console.log('resetTabs isProductLevel', isProductLevel)
    this.answerContainer = this.questionContainer.querySelectorAll('.answerpanel > .answers .answers')
    // console.log('resetTabs questionContainer',  this.questionContainer)
    // console.log('resetTabs answerContainer',  this.answerContainer)
    // console.log('resetTabs currentTab',  this.currentTab)
    // console.log('resetTabs hasOwnProperty hideif',  this.questionContainer.dataset.hasOwnProperty('hideif'))

    if (this.currentTab) {
      const currentTabParentQuestionContainer = SelectorEngine.parents(this.currentTab, '[id^=questionpopup]').pop();
      if (currentTabParentQuestionContainer && currentTabParentQuestionContainer.id !== this.questionContainer.id) {
        // console.log('resetTabs this.currentTab is not in the same questionpopup element')
        this.currentTab = this.questionContainer.querySelector('.answerpanel > .answers .answers:not(.hidden-hideif)')
        // console.log('resetTabs this.currentTab is not in the same questionContainer, grabbing the first one in the current questionContainer', this.currentTab)
      }
    }

    for (const el of this.answerContainer) {
      if ((hasQuantiPlaceholder || isProductLevel) && el.id.includes('dish')) {
        el.hide()
        document.querySelectorAll('div.items .dishes li[id^=dish]').forEach(li => li.classList.add('hidden'))
        continue
      }

      if (el.querySelectorAll('input[type=radio]:checked, input[type=checkbox]:checked').length === 0 &&
          !uncompleted.nodeType &&
          (!hasQuantiPlaceholder && !isProductLevel)
      ) {
        // console.log('resetTabs uncompleted ', el)
        uncompleted = el
      } else {
        if (!this.questionContainer.dataset.hasOwnProperty('hideif') ||
            !this.questionContainer.dataset.hideif.includes('quanti')
        ) {
          // console.log('resetTabs hide el', el)
          el.hide();
        }
      }
    }

    this.itemContainer = this.questionContainer.querySelector('div.items');
    /*
    if (isMobile) {
      const itemsCrs = this.questionContainer.querySelectorAll('div.items > ul > li')
      for (const el of itemsCrs) {
        el.hide()
      }
    }
    */
    const currentDisplay = document.querySelector('[id^="questionpopup_"]:not(.hidden):not(.hidden-if)')
    const form = currentDisplay.closest('form')
    if (currentDisplay){
      const currentAnswer = currentDisplay.querySelector('div.answers:not(.hidden)')
    }
    const completed = this.questionContainer.querySelectorAll('div.items .products .completed:not(.hidden-hideif), div.items .dishes .completed:not(.hidden-hideif)')
    const products = this.questionContainer.querySelectorAll('div.items .products li:not(.hidden), div.items .dishes li:not(.hidden)')
    const notCompProducts = this.questionContainer.querySelectorAll('div.items .products li:not(.completed)')

    if (hasQuantiPlaceholder || isProductLevel) {
      // console.log('resetTabs hasQuantiPlaceholder > uncompleted', uncompleted)
      uncompleted = this.questionContainer.querySelector('div.items li[id*=\'product\']:not(.completed,.hidden-hideif)')
    }

    // console.log('resetTabs completed', completed);
    // console.log('resetTabs products', products)
    // console.log('resetTabs uncompleted', uncompleted)

    if (completed.length > 0) {
      let lastCompleted = {}
      if (dir < 0 && completed.length == products.length) {
        lastCompleted = completed[0]
        // console.log('a')
        this.openTab(lastCompleted.id)
      } else if (dir > 0 && completed.length == products.length) {
        let f = 0
        let nxTabId= ''
        let nxTab = this.currentTab.nextElementSibling
        if (nxTab) {
          nxTabId = nxTab.id
        } else {
          nxTabId = this.currentTab.id
        }

        for (let i = 0; i < completed.length; i++) {
          f = i
          if (completed[i].id == nxTabId) {
            break
          }
        }

        //lastCompleted = completed[]
        // console.log('b')
        if (f < 0) f = 0
        if (f < (products.length - 1)) f++
        if (products[f].classList.contains('selected')) this.nextTab()
        else this.openTab(completed[f].id)
      } else {
        lastCompleted = completed[completed.length - 1]
        if (dir > 0 && completed.length == products.length ) {
          this.openTab(completed[0].id)
        } else if (completed.length < products.length) this.nextTab()
        else this.openTab(lastCompleted.id)
      }
      //if (dir >= 0 && completed.length == 1) this.nextTab()
    } else if (uncompleted && this.hasattr(uncompleted, 'id')) {
      // console.log('d')
      this.openTab(uncompleted.id)
    } else if (completed.length > 0 && !this.currentItem) {
      // console.log('e')
      this.openTab(completed[0].id)
    } else if (completed.length > 0 && this.currentItem && sibling != this.currentTab) {
      // console.log('f')
      this.openTab(sibling.id)
    } else {
      // console.log('g')
      const removeDishSelector = isProductLevel ? ',[id^=dish]' : ''
      this.openTab(this.questionContainer.querySelector(`div.answers .answers:not(.hidden-hideif${removeDishSelector})`));
    }
  }

  static checkKey(keypressed) {
    if (!keypressed) {
      keypressed = document.all.event
    }

    switch (keypressed.keyCode) {
      case Event.KEY_UP:
        this.previousTab()
        break
      case Event.KEY_DOWN:
        this.nextTab()
        break
      default:
        return true
    }

    return false
  }

  static check(checkbox) {
    let completed = document.querySelector('.side-days');
    const ansPanel = checkbox.closest('.answers')
    const fields = ansPanel.querySelectorAll('input')
    for (let j = 0; j < fields.length && !completed; j++) {
      if (fields[j].checked) {
        completed = true
      }
    }

    const thisProd = ansPanel.id
    const thisDiv = document.getElementById('questionpopup_' + window.ff.currentNeedsQ )

    if (thisDiv.querySelectorAll('[id="' + thisProd + '"]').length > 1) {
      const thisProdLi = thisDiv.querySelector('.tab_question  > .items > .courses .products li[id="' + thisProd + '"]')
      if (thisProdLi) {
        this.currentItem = thisProdLi
      }
    }

    if (completed) {
      this.currentItem.classList.add('completed')
    } else {
      this.currentItem.classList.remove('completed')
    }
  }

  static ask_need_questions(evTrgt) {
    const url = new URL(window.location.href)
    const SES = url.searchParams.get('SES') ? url.searchParams.get('SES') : ''

    window.ff.currentNeedsQ = 1

    this._dialog = Dialog.confirm(evTrgt, {
      extraClasses: 'modal-xl',
      content: {
        url: window.ff.pages.ajax,
        options: {
          parameters: {
            act: 'ask_need_questions',
            occasion_id: window.ff.occasion_id,
            category_id: window.ff.category_id,
            popupnr: this.current,
            edit: window.ff.edit,
            SES
          }
        }
      },
      events: {
        shown() {

          Needs.checkBlockConditions()

          EventHandler.on(document.querySelectorAll('.modal-body input[data-placeholder], .modal-body select[data-placeholder], .modal-body textarea[data-placeholder]'), EVENT_CHANGE, event => {
            Needs.checkRadioConditions(event.target)
          })

          if (document.querySelector('.popup-1 > .summary')) {
            const oldDialog = document.querySelector('.popup-1 > .summary').closest('.modal')
            if (oldDialog) {
              const modal = Dialog.getInstance(oldDialog)
              modal.dispose()
            }
          }

          const checkboxes = document.querySelectorAll('div#questionpopup_' + window.ff.currentNeedsQ + ' input')
          for (const el of checkboxes) {
            if (!el.classList.contains('check_all')) {
              EventHandler.on(el, EVENT_CHANGE, ev => {
                if (!ev.target.checked) {
                  document.querySelector('.check_all').checked = false
                }
              })
            }
          }
          EventHandler.on(document.querySelector('.allowall'), EVENT_CLICK, ev => {
            Needs.select_all(ev.target)
          })
          EventHandler.on(document.querySelector('.check_all'), EVENT_CHANGE, ev => {
            Needs.check_all(ev.target)
          })
          if (document.getElementById('questiontype' + window.ff.currentNeedsQ) && document.getElementById('questiontype' + window.ff.currentNeedsQ).getValue() == 'TABC') {
            Needs.resetTabs(0)
          }

          for (const el of document.querySelectorAll('[id^="questionpopup_"]')) {
            if (el.id !== 'questionpopup_' + window.ff.currentNeedsQ) {
              el.hide()
            }
          }

          if (!document.querySelector('[id^="questionpopup_' + window.ff.currentNeedsQ + '"]') &&
            document.querySelector('[name*="singleperson"]') &&
            document.querySelector('[name*="singleperson"]').value == 1) {
            const form = document.querySelector('[name*="singleperson"]').closest('form')
            form.submit()
          }

          if (document.querySelector('[id^="questionpopup_' + window.ff.currentNeedsQ + '"]')) {
            document.getElementById('questionpopup_' + window.ff.currentNeedsQ).show()
          }
        }
      },
      buttons: [
        {
          label: getPlaceholder('diary_need_previous_btn') || 'previous',
          action: ev => {
            this.prev(ev)
          }
        },
        {
          label: getPlaceholder('diary_need_next_btn') || 'next',
          action: ev => {
            this.next(ev)
          }
        }
      ]
    })
  }

  static checkBlockConditions() {
    const answers = (typeof window.ff.answers === 'object') ? window.ff.answers : JSON.parse(window.ff.answers)

    const answerKeys = Object.keys(answers)
    for (const placeholder of [...document.querySelectorAll('.modal-body [data-hideif]')]) {
      let expr = placeholder.dataset.hideif
      //console.log('or '+ expr)
      let hideThis = false
      if (expr.length !== 0){
        expr = getConditions(answers, expr, answerKeys)
        try{
          hideThis = eval(expr)
          //console.log((hideThis ? 'hide' : 'show') +' '+ placeholder.nodeName +' '+ placeholder.dataset.hideif +' '+ expr )
          if (hideThis) {
            if (!placeholder.classList.contains('hidden-if')){
              placeholder.hideif()
            }
          }
          if (!hideThis) {
            if (placeholder.classList.contains('hidden-if')){
              placeholder.showif()
            }
          }
        } catch (error) {
          console.log(expr +' has error '+ error.message)
        }
      }
    }
  }

  static setupMobilePrep(thisDiv) {
    document.body.classList.add('mobile')
    window.ff.PrepCourses = thisDiv.querySelectorAll('.form-row.course')
    window.ff.PrepProducts = thisDiv.querySelectorAll('.need_answers .form-row.product, .need_answers .form-row.dish, .tab_question  > .answerpanel > .answers > .answers')
    thisDiv.currentNeedsCourse = 0
    thisDiv.mobileState = 0

    for (const course of [...window.ff.PrepCourses]) {
      course.classList.add('hidden')
    }

    for (const product of [...window.ff.PrepProducts]) {
      product.classList.add('hidden')
    }

    if (window.ff.PrepCourses && window.ff.PrepCourses.length > 0) {
      thisDiv.mobileState++
      window.ff.PrepCourses[0].classList.remove('hidden')
    }

    if (window.ff.PrepProducts && window.ff.PrepProducts.length > 0) {
      thisDiv.mobileState++
      window.ff.PrepProducts[0].classList.remove('hidden')
    }

    this._setupMobileFlippers()
  }

  static next(ev) {
    const currentDisplay = document.querySelector('[id^="questionpopup_"]:not(.hidden)')
    const currentDisplayHasQuantiPlaceholder = currentDisplay.dataset.hasOwnProperty('placeholder') && currentDisplay.dataset.placeholder.includes('quanti')
    const currentDisplayIsProductLevel = currentDisplay.dataset.hasOwnProperty('level') && currentDisplay.dataset.level.includes('product')
    // console.log('next currentDisplay', currentDisplay)
    // console.log('next currentDisplayHasQuantiPlaceholder', currentDisplayHasQuantiPlaceholder)
    // console.log('next currentDisplayIsProductLevel', currentDisplayIsProductLevel)
    window.ff.currentNeedsQ = currentDisplay.id.split('_')[1]
    const form = currentDisplay.closest('form')
    for (const el of ev.querySelectorAll('.btn')) {
      el.blur()
    }

    const validation = Number.parseInt(document.getElementById('validation_' + window.ff.currentNeedsQ).getValue(), 10)
    if (validation === 4) {
      const removeDishesSelector = currentDisplayHasQuantiPlaceholder || currentDisplayIsProductLevel ? ',[id^=dish]' : '';
      const div = currentDisplay.querySelector('div.answers div.answers:not(.hidden,.hidden-hideif)')
      const divs = currentDisplay.querySelectorAll(`div.answers div.answers:not(.hidden-hideif${removeDishesSelector})`)

      let notUsed = true
      const fields = div.querySelectorAll('input:not(:disabled)')
      for (let j = 0; j < fields.length && notUsed; j++) {
        if (fields[j].checked) {
          notUsed = false
        }
      }

      if (notUsed && (div === divs[0] || div === divs[divs.length-1])) {
        Dialog.alert(form, { content: getPlaceholder('diary_js_invalid_need_question') })
        return false
      }

      if (div !== divs[divs.length - 1]) {
        this.resetTabs(1)
        return false
      }
    }

    if (this.validate()) {
      let orDisplay = window.ff.currentNeedsQ
      let nextDisplay = document.getElementById('questionpopup_' + window.ff.currentNeedsQ)
      const questions = document.querySelectorAll('[id^=questionpopup_]')
      //console.log(togglers);

      try {
        questions.forEach(function(el) {
          console.log('test: '+ parseInt(el.id.replace("questionpopup_", ''), 10) +'<='+ window.ff.currentNeedsQ )
          console.log('current: '+ parseInt(currentDisplay.id.replace("questionpopup_", ''), 10) )
          if (parseInt(el.id.replace("questionpopup_", ''), 10) <= parseInt(currentDisplay.id.replace("questionpopup_", ''), 10) ) {
            return
          }
          window.ff.currentNeedsQ++
          nextDisplay = document.getElementById('questionpopup_' + window.ff.currentNeedsQ)
          if (nextDisplay) {
            if (nextDisplay.classList.contains('hidden-if')) {
              if (isNaN(parseInt(nextDisplay.id, 10))) form.submit()
              console.log('next should be: '+ parseInt(nextDisplay.id, 10) + nextDisplay.classList )
              return
            } else {
              throw BreakException
            }
          }
        })
      } catch (e) {
        console.log('next: '+ window.ff.currentNeedsQ )
      }

      if (window.ff.currentNeedsQ = orDisplay) window.ff.currentNeedsQ++

      nextDisplay = document.getElementById('questionpopup_' + window.ff.currentNeedsQ)
      //window.ff.currentNeedsQ++

      if (nextDisplay) {
        const numAnswers = nextDisplay.querySelectorAll('.products li[id^=product]');

        if (numAnswers.length) {
          const hiddenHideifAnswers = Array.from(numAnswers).filter(li => li.classList.contains('hidden-hideif')).length || 0;

          if (numAnswers.length === hiddenHideifAnswers) {
            window.ff.currentNeedsQ++;

            nextDisplay = document.getElementById('questionpopup_' + window.ff.currentNeedsQ)
            if (!nextDisplay) {
              form.submit();
            }
          }

        }

        // Hides all the elements that don't have the ".hidden-hideif" class
        // They are also handled by the this.prev() method
        currentDisplay.querySelectorAll('div.answers .answers:not(.hidden-hideif)').forEach(element => element.hide())
        currentDisplay.hide()

        // Hides the dishes that doesn't exist in the dictionary and can't be answered
        nextDisplay.querySelectorAll('ul.dishes li').forEach(li => {
          const courseAnswers = nextDisplay.querySelector(`div.answers .answers[id=${li.id}]:not(.hidden-hideif)`)

          if (!courseAnswers) {
            li.hide()
          }
        });

        nextDisplay.show()
        for (const neb of [...nextDisplay.querySelectorAll('.tab_question  > .items > .courses .products li')]) {
          neb.classList.remove('selected')
        }

        if (isMobile && nextDisplay.querySelector('[name*="2-20"]')) {
          this.setupMobilePrep(nextDisplay)
        }

        // next_display.scrollIntoView();
        if (document.getElementById('questiontype' + window.ff.currentNeedsQ) && document.getElementById('questiontype' + window.ff.currentNeedsQ).getValue() == 'TABC') {
          this.currentItem = false
          this.resetTabs(1)
        }
        // this.resetHeight(this._dialog);
      } else {
        form.submit()
      }
    } else if (document.getElementById('questionid' + window.ff.currentNeedsQ).value == window.ff.whwquestionid) {
      Dialog.alert(form, { content: getPlaceholder('diary_js_invalid_who_had_what') })
    } else {
      Dialog.alert(form, { content: getPlaceholder('diary_js_invalid_need_question') })
    }
  }

  static prev(ev) {
    const currentDisplay = document.querySelector('[id^="questionpopup_"]:not(.hidden)')
    window.ff.currentNeedsQ = currentDisplay.id.split('_')[1]
    for (const el of ev.querySelectorAll('.btn')) {
      el.blur()
    }

    if (window.ff.currentNeedsQ <= 1) {
      const thisModal = document.getElementById('questionpopup_' + window.ff.currentNeedsQ).closest('.modal')
      const modal = Dialog.getInstance(thisModal)
      modal.dispose()
      return false
    }

    // document.getElementById("questionpopup_" +ff.currentNeedsQ).hide();
    window.ff.currentNeedsQ--

    let orDisplay = window.ff.currentNeedsQ
    let nextDisplay = document.getElementById('questionpopup_' + window.ff.currentNeedsQ)
    const questions = document.querySelectorAll('[id^=questionpopup_]')
    //console.log(togglers);
    try {
      questions.reverse().forEach(function(el) {
        if (parseInt(el.id.replace("questionpopup_", ''), 10) >= parseInt(currentDisplay.id.replace("questionpopup_", ''), 10)) {
          window.ff.currentNeedsQ--
          return
        }
        nextDisplay = document.getElementById('questionpopup_' + window.ff.currentNeedsQ)
        if (nextDisplay) {
          if (nextDisplay.classList.contains('hidden-if')) {
            window.ff.currentNeedsQ--
            return
          } else {
            throw BreakException
          }
        }
      })
    } catch (e) {
    }

    // Hides all the elements th
    //     // They are also handled by the this.next() methodat don't have the ".hidden-hideif" class
    currentDisplay.querySelectorAll('div.answers .answers:not(.hidden-hideif)').forEach(element => element.hide())
    currentDisplay.hide()
    if (isMobile && nextDisplay.querySelector('[name*="2-20"]')) {
      this.setupMobilePrep(nextDisplay)
    }

    // Checks if the hideif questionpopup_ has all children hidden. If so, the nextDisplay will be the previous from it
    const numAnswers = nextDisplay.querySelectorAll('.products li[id^=product]');
    if (numAnswers.length) {
      const hiddenHideifAnswers = Array.from(numAnswers).filter(li => li.classList.contains('hidden-hideif')).length || 0;

      if (numAnswers.length === hiddenHideifAnswers) {
        window.ff.currentNeedsQ--;
        nextDisplay = document.getElementById('questionpopup_' + window.ff.currentNeedsQ)
      }
    }

    nextDisplay.show()
    if (document.getElementById('questiontype' + window.ff.currentNeedsQ) && document.getElementById('questiontype' + window.ff.currentNeedsQ).getValue() == 'TABC') {
      this.resetTabs(-1)
    }

    return false
  }

  static show_occasion_summary(e) {
    const url = new URL(window.location.href)
    const SES = url.searchParams.get('SES') ? url.searchParams.get('SES') : ''
    e = e || window.event
    const isObject = typeof e === 'object' // is the given argument an object
    const isEvent = isObject ? e.hasOwnProperty('target') : false

    this.constructor._dialog = Dialog.confirm(
      (isEvent) ? e.target : null,
      {
        extraClasses: 'modal-lg',
        content: {
          url: window.ff.pages.ajax,
          options: {
            parameters: {
              act: 'show_occasion_summary',
              occasion_id: window.ff.occasion_id,
              dish_id: window.ff.currentdish.usage_dish_id || window.ff.short_process_id,
              usage_dish_id: window.ff.currentdish.usage_dish_id,
              completed: (!window.ff.courses || window.ff.current_course + 1 == window.ff.courses.length ? 1 : 0),
              edit: window.ff.edit,
              SES
            }
          }
        },
        buttons: [
          {
            label: getPlaceholder('diary_close_summary_btn') || 'add more',
            data: { 'ff-dismiss': 'dialog' }
          },
          {
            label: getPlaceholder('diary_meal_complete_btn') || 'Complete',
            action: ev => {
              this.ask_need_questions(ev)
            }
          }
        ],
        events: {
          shown: () => {
            const dialog = SelectorEngine.findOne('.modal-dialog')
            const brandD = document.querySelector('.modal-dialog .brands')
            //console.log(brandD)
            if (brandD) {
              //console.log(dialog.getButtons())
              const contButton = document.querySelector('.modal-footer button:not([data-ff-dismiss])')
              if (contButton) contButton.hide()
            }
          }
        }
      }
    )
  }

  static select_all(btn) {
    const divs = this.questionContainer.querySelectorAll('div.answers div.answers')
    const fields = divs[0].querySelectorAll('input')
    const fieldsChecked = []
    let used = false
    for (let j = 0; j < fields.length && !used; j++) {
      if (fields[j].checked) {
        fieldsChecked.push(fields[j].value)
      }
    }
    if (fieldsChecked.length > 0) {
      for (let i = 1; i < divs.length; i++) {
        if (divs[i]) {
          let theseFields = divs[i].querySelectorAll('input')
          for (let j = 0; j < theseFields.length; j++) {
            if (fieldsChecked.includes(theseFields[j].value)) {
              theseFields[j].checked = true
              document.querySelector('li#' + divs[i].id).classList.add('completed')
            }
          }
        }
      }
    }
    return false
  }

  static check_all(checkAllBox) {
    const checkboxes = this.get_inputs()
    for (const el of checkboxes) {
      el.checked = checkAllBox.checked
    }
  }

  static get_inputs() {
    return document.querySelectorAll('div#questionpopup_' + window.ff.currentNeedsQ + ' input')
  }

  static validate() {
    const validation = Number.parseInt(document.getElementById('validation_' + window.ff.currentNeedsQ).getValue(), 10)

    if (validation === 0) {
      return true
    }

    if (validation === 4) {
      const tabsOK = this.validateTabs()
      if (!tabsOK) {
        this.resetTabs(0)
      }

      return tabsOK
    }

    const rows = document.querySelectorAll('div#questionpopup_' + window.ff.currentNeedsQ + ' div.row:not(.empty)')
    const columnsUsed = []
    for (let i = 1; i < rows[0].querySelectorAll('.needshead').length; i++) {
      columnsUsed[i] = false
    }

    let allItemsUsed = true
    for (let nr = 1; nr < rows.length; nr++) {
      if (rows[nr].classList.contains('headrow')) {
        continue
      }

      if (rows[nr].querySelectorAll('input[type=checkbox]').length == 0) {
        continue
      }

      const columns = rows[nr].querySelectorAll('div')
      let currentItemUsed = false
      for (let i = 1; i < columns.length; i++) {
        const checkbox = columns[i].querySelector('input[type=checkbox]')
        if (checkbox && checkbox.checked == true) {
          columnsUsed[i] = true
          currentItemUsed = true
        }
        else if (checkbox && document.getElementById('guest_id') && checkbox.value == document.getElementById('guest_id').value && checkbox.checked == false) {
          columnsUsed[i] = true
        }
      }

      if (!currentItemUsed) {
        allItemsUsed = false
      }
    }

    if (validation & 1 && !allItemsUsed) {
      return false
    }

    if (validation & 2) {
      for (let i = 1; i < columnsUsed.length; i++) {
        if (!columnsUsed[i]) {
          return false
        }
      }
    }

    return true
  }

  static validateTabs() {
    const containerHasQuantiPlaceholder = this.questionContainer.dataset.hasOwnProperty('placeholder') &&
        this.questionContainer.dataset.placeholder.includes('quanti')
    const containerHasProductLevel = this.questionContainer.dataset.hasOwnProperty('level') &&
        this.questionContainer.dataset.level.includes('product')
    const removeDishSelector = (containerHasQuantiPlaceholder || containerHasProductLevel) ? ',[id^=dish]' : '';
    const divs = this.questionContainer.querySelectorAll(`div.answers div.answers:not(.hidden-hideif${removeDishSelector})`)

    for (let i = 0; i < divs.length; i++) {
      const fields = divs[i].querySelectorAll('input')
      let used = false
      for (let j = 0; j < fields.length && !used; j++) {
        if (fields[j].checked) {
          used = true
        }
      }

      if (!used) {
        return false
      }
    }

    return true
  }

  static _NodeListforEach(array, callback, scope) {
    for (let i = 0; i < array.length; i++) {
      callback.call(scope, i, array[i]); // passes back stuff we need
    }
  }

  static _flipPage(e) {
    const formOK = this.validatePanel(e)
    if (!formOK) {
      return false
    }

    const targ = e.target
    if (targ instanceof Element) {
      this.el.mobileStates[this.el.mobileState - 1].hide()
      if (targ.classList.contains('prevBtn')) {
        this.el.mobileState--
      } else if (targ.classList.contains('nextBtn')) {
        this.el.mobileState++
      }

      if (this.el.mobileState === this.el.mobileStates.length) {
        const paddles = document.querySelectorAll('.button-cont [id*="_clone"]')
        const submits = document.querySelectorAll('.button-cont .hidden')

        this._NodeListforEach(paddles, (index, paddle) => {
          if (paddle instanceof Element) {
            paddle.hide()
          }
        })

        this._NodeListforEach(submits, (index, submit) => {
          if (submit instanceof Element) {
            submit.show()
          }
        })
      }

      this._showPageState()
    }
  }

  static validatePanel(div) {
    const divs = div.querySelectorAll('.need_answers .form-row.product:not(.hidden), .need_answers .form-row.dish:not(.hidden)')
    for (const thisDiv of [...divs]) {
      if (!thisDiv.classList.contains('hidden')) {
        let used = false
        let fields = thisDiv.querySelectorAll('input')

        for (let j = 0; j < fields.length && !used; j++) {
          if (!fields[j].getclosest('.hidden') && fields[j].checked) {
            used = true
          }
        }

        if (!used) {
          return false
        }
      }
    }

    return true
  }

  static getPreviousSibling(elem, selector) {
    // Get the next sibling element
    let sibling = elem.previousElementSibling
    // If there's no selector, return the first sibling
    if (!selector) {
      return sibling
    }

    // If the sibling matches our selector, use it
    // If not, jump to the next sibling and continue the loop
    while (sibling) {
      if (sibling.matches(selector)) {
        return sibling
      }

      sibling = sibling.previousElementSibling
    }
  }

  static _flipPanel(e) {
    const thisDiv = document.getElementById('questionpopup_' + window.ff.currentNeedsQ )
    const formOK = this.validatePanel(thisDiv)
    if (!formOK) {
      return false
    }
    window.ff.PrepCourses = thisDiv.querySelectorAll('.need_answers .form-row.course')
    window.ff.PrepProducts = thisDiv.querySelectorAll('.need_answers .form-row.product, .need_answers .form-row.dish, .tab_question  > .answerpanel > .answers > .answers')

    let dirTn = 'next'
    if ((e.currentTarget && e.currentTarget.classList.contains('prevBtn')) || (e.classList && e.classList.contains('prevBtn'))) {
      dirTn = 'prev'
    }

    //let itemsInCourse = thisDiv.querySelectorAll('.need_answers .form-row.course, .need_answers .form-row.product, .need_answers .form-row.dish')
    let itemsInCourse = thisDiv.querySelectorAll('.need_answers .form-row.product, .need_answers .form-row.dish, .tab_question  > .answerpanel > .answers > .answers')
    let courses = thisDiv.querySelectorAll('.need_answers .form-row.course')
    for (let j = 0; j < itemsInCourse.length; j++) {
      if (!itemsInCourse[j].classList.contains('hidden')) {
        let curCount = 0
        thisDiv.currentNeedsCourse = 0
        if (itemsInCourse) {
          if (thisDiv.mobileState < itemsInCourse.length && dirTn == 'next') {
            for (const item of [...itemsInCourse]) {
              item.classList.add('hidden')
            }

            itemsInCourse[thisDiv.mobileState].classList.remove('hidden')
            if (this.hasattr(itemsInCourse[thisDiv.mobileState], 'id')) {
              const thisProd = itemsInCourse[thisDiv.mobileState].id
              if (thisDiv.querySelectorAll('[id="' + thisProd + '"]').length > 1) {
                for (const neb of [...thisDiv.querySelectorAll('.tab_question  > .items > .courses .products li')]) {
                  neb.classList.remove('selected')
                }

                const thisProdLi = thisDiv.querySelector('.tab_question  > .items > .courses .products li[id="' + thisProd + '"]')
                if (thisProdLi) {
                  thisProdLi.classList.add('selected')
                }
              }
            }

            if (courses.length) {
              for (const course of [...courses]) {
                course.classList.add('hidden')
              }

              const prevCourse = this.getPreviousSibling(itemsInCourse[thisDiv.mobileState], '.form-row.course')
              if (prevCourse) {
                prevCourse.classList.remove('hidden')
              }
            }

            thisDiv.mobileState++
          } else if (thisDiv.mobileState == itemsInCourse.length && dirTn == 'next') {
            const paddles = document.querySelectorAll('.footer-buttons [id*="_clone"]')
            const submits = document.querySelectorAll('.footer-buttons .hidden')

            for (const submit of [...submits]) {
              submit.show()
            }

            document.getElementById(document.querySelector('.footer-buttons [id*="_clone"].nextBtn').id.replace('_clone', '')).click()
            for (const paddle of [...paddles]) {
              paddle.remove()
            }
          } else if (dirTn === 'prev') {
            let foundItem = false
loop1:
            for (let z = thisDiv.mobileState - 2; z >= 0; z--) {
              if (itemsInCourse[z].classList.contains('product') || itemsInCourse[z].classList.contains('dish')){
                foundItem = true
                break loop1
              }
            }

            if (thisDiv.mobileState > 1 && foundItem) {
              for (const item of [...itemsInCourse]) {
                item.classList.add('hidden')
              }
              itemsInCourse[thisDiv.mobileState - 2].classList.remove('hidden')
              if (courses.length) {
                for (const course of [...courses]) {
                  course.classList.add('hidden')
                }

                let prevCourse = this.getPreviousSibling(itemsInCourse[thisDiv.mobileState - 2], '.form-row.course')
                if (prevCourse) {
                  prevCourse.classList.remove('hidden')
                }
              }

/*
              if (itemsInCourse[thisDiv.mobileState - 2].classList.contains('course')) {
loop2:
                for (let z = thisDiv.mobileState - 3; z >= 0; z--) {
                  if (itemsInCourse[z].classList.contains('course')){
                    itemsInCourse[z].classList.remove('hidden')
                    break loop2
                  }
                }
                itemsInCourse[thisDiv.mobileState - 3].classList.remove('hidden')
                thisDiv.mobileState--
                itemsInCourse[thisDiv.mobileState - 1].classList.add('hidden')
              } else {
                itemsInCourse[thisDiv.mobileState - 2].classList.remove('hidden')
              }
*/
              thisDiv.mobileState--
            } else {
              const paddles = document.querySelectorAll('.footer-buttons [id*="_clone"]')
              const submits = document.querySelectorAll('.footer-buttons .hidden')

              for (const submit of [...submits]) {
                submit.show()
              }

              document.getElementById(document.querySelector('.footer-buttons [id*="_clone"].prevBtn').id.replace('_clone', '')).click()
              for (const paddle of [...paddles]) {
                paddle.remove()
              }
            }
          }

          break
        }
      }
    }
  }

  static _setupMobileFlippers() {
    if (isMobile) {
      const nodes = document.querySelectorAll('.footer-buttons button')
      for (const node of [...nodes]) {
        const clone = node.cloneNode(true)
        node.classList.add('hidden')
        clone.id += '_clone'

        if (node.previousSibling == null) {
          clone.classList.add('prevBtn')
        } else {
          clone.classList.add('nextBtn')
        }

        EventHandler.on(clone, EVENT_CLICK, event => {
          this._flipPanel(event)
        })
        document.querySelector('.footer-buttons').append(clone)
      }
    }
  }
}

EventHandler.on(document, EVENT_CLICK_DATA_API, SELECTOR_DATA_TOGGLE, event => {
  let trgt = event.target
  let attribs = Manipulator.getDataAttributes(trgt)

  if (trgt.tagName === 'A' || (trgt.parentNode && trgt.parentNode.tagName === 'A') || trgt.tagName === 'AREA') {
    event.preventDefault()
  }

  if (!trgt || !attribs.hasOwnProperty('action')) {
    trgt = event.target.closest(SELECTOR_DATA_TOGGLE)
    attribs = Manipulator.getDataAttributes(trgt)
  }

  Needs[attribs.action](trgt, (attribs.hasOwnProperty('data') ? attribs.data : {}))
})

export default Needs


import {
  defineJQueryPlugin,
  getElementFromSelector,
  isVisible,
  isElement,
  ajaxLoad,
  getPlaceholder
} from './util/index'
import { sanitizeHtml } from './util/sanitizer'
import Data from './dom/data'
import EventHandler from './dom/event-handler'
import Manipulator from './dom/manipulator'
import SelectorEngine from './dom/selector-engine'
import Modal from './modal'
import api from './api'

const NAME = 'dialog'
const DATA_KEY = 'ff.dialog'
const EVENT_KEY = `.${DATA_KEY}`
const DATA_API_KEY = '.data-api'

const EVENT_CLICK = `click${EVENT_KEY}`
const EVENT_HIDE = `hide${EVENT_KEY}`
const EVENT_HIDDEN = `hidden${EVENT_KEY}`
const EVENT_SHOW = `show${EVENT_KEY}`
const EVENT_SHOWN = `shown${EVENT_KEY}`
const EVENT_FOCUSIN = `focusin${EVENT_KEY}`
const EVENT_CLICK_DISMISS = `click.dismiss${EVENT_KEY}`
const EVENT_MOUSEUP_DISMISS = `mouseup.dismiss${EVENT_KEY}`
const EVENT_MOUSEDOWN_DISMISS = `mousedown.dismiss${EVENT_KEY}`
const EVENT_CLICK_DATA_API = `click${EVENT_KEY}${DATA_API_KEY}`

const CLASS_NAME_FADE = 'fade'
const CLASS_NAME_SHOW = 'show'  //needed by parent class Modal
const CLASS_NAME_OPEN = 'modal-open'

const SELECTOR_DIALOG = '.modal-dialog'
const SELECTOR_DATA_TOGGLE = '[data-ff-toggle="dialog"]'
const SELECTOR_DATA_DISMISS = '[data-ff-dismiss="dialog"]'
const SELECTOR_CONTENT = '.modal-body'
const SELECTOR_FOOTER = '.modal-footer'

const Default = {
  ...Modal.Default,
  buttons: null,
  extraClasses: null,
  template: '<div class="modal fade dialog" tabindex="-1" aria-hidden="true">' +
    '<div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">' +
    '<div class="modal-content">' +
    '<div class="modal-body"></div>' +
    '<div class="modal-footer"></div>' +
    '</div>' +
    '</div>' +
    '</div>'
}

const DefaultType = {
  ...Modal.DefaultType,
  title: '(string)',
  content: '(string|element|function)',
  footer: '(string|element|function)'
}

class Dialog extends Modal {
  constructor(element, config) {
    super(element)
    this.ajaxContent = false
    this.ajaxLoaded = true
    this._config = this._getConfig(config)
    this._element = this.getDialogElement(element)
    this.setContent()
    this._dialog = SelectorEngine.findOne(SELECTOR_DIALOG, this._element)
    this._element.style.display = 'none'
    document.body.append(this._element)
  }

  static get Default() {
    return Default
  }

  static get NAME() {
    return NAME
  }

  static get DATA_KEY() {
    return DATA_KEY
  }

  static get Event() {
    return Event
  }

  static get EVENT_KEY() {
    return EVENT_KEY
  }

  static get DefaultType() {
    return DefaultType
  }

  _getConfig(config) {
    const dataAttributes = Manipulator.getDataAttributes(this._element)
    config = {
      ...this.constructor.Default,
      ...dataAttributes,
      ...(typeof config === 'object' && config ? config : {})
    }

    if (config.sanitize) {
      config.template = sanitizeHtml(config.template, config.allowList, config.sanitizeFn)
    }

    return config
  }

  getDialogElement(el) {
    if (this._element) {
      return this._element
    }

    if (el.innerHTML.search('.modal-dialog').length > 0) {
      this._element = el
      return this._element
    }

    const element = document.createElement('div')
    element.innerHTML = this._config.template

    this._element = element.children[0]
    if (this._config.extraClasses) {
      this._element.querySelector('.modal-dialog').classList.add(this._config.extraClasses)
    }

    return this._element
  }

  setContent() {
    this.setElementType(this._dialog, this.getType())

    let content = this._getContent()
    if (content && typeof content === 'object') {
      this.ajaxContent = true
      this.ajaxLoaded = false
      this._runAjaxRequest(content, this._dialog)

      return
    }

    if (content && typeof content === 'function') {
      content = content || ''
      content = content.call(this._element)
    }

    this.setElementContent(SelectorEngine.findOne(SELECTOR_CONTENT, this._dialog), content)
  }

  _runAjaxRequest(parameters, dialog) {
    if (parameters.options === null) {
      parameters.options = {}
    }

    api.post(
      parameters.url,
      {
        ...parameters.options.parameters
      },
      true
    ).then(data => {
      this.ajaxLoaded = true
      this.setElementContent(SelectorEngine.findOne(SELECTOR_CONTENT, dialog), data)
      this.show()
      ajaxLoad()
      EventHandler.trigger(dialog, EVENT_SHOWN)
    })
  }

  setElementType(element, content) {
    if (element === null) {
      return
    }

    element.firstChild.classList.add(content)
  }

  _getContent() {
    return Manipulator.getDataAttribute(this._element, 'content') || this._config.content
  }

  getType() {
    let type = Manipulator.getDataAttribute(this._element, 'type')

    if (!type) {
      type = typeof this._config.type === 'function' ?
        this._config.type.call(this._element) :
        this._config.type
    }

    return type
  }

  getFooter() {
    const container = document.createElement('div')
    container.className = 'close-button'
    const icon = document.createElement('button')
    if (this._element && this.getType() === 'alert') {
      icon.className = 'close'
    }

    icon.setAttribute('aria-label', 'close')
    icon.dataset.ffDismiss = 'dialog'
    container.append(icon)
    return container
  }

  addButton(button) {
    this._config.buttons.push(button)
    return this
  }

  addButtons(buttons) {
    const that = this
    for (const button of buttons) {
      that.addButton(button)
    }

    return this
  }

  getButtons() {
    return this._config.buttons
  }

  setButtons(buttons) {
    this._config.buttons = buttons
    return this.updateButtons()
  }

  updateButtons() {
    if (this.getButtons().length === 0) {
      SelectorEngine.findOne(SELECTOR_FOOTER, this._dialog).hide()
    } else {
      SelectorEngine.findOne(SELECTOR_FOOTER, this._dialog).show()
      SelectorEngine.findOne(SELECTOR_FOOTER, this._dialog).append(this.createFooterButtons())
    }

    return this
  }

  createFooterButtons() {
    const that = this
    const container = document.createElement('div')

    container.classList.add('footer-buttons')
    this.indexedButtons = {}
    for (const button of this._config.buttons) {
      if (!button.id) {
        button.id = Dialog.newGuid()
      }

      const newButton = that.createButton(button)
      that.indexedButtons[button.id] = newButton
      container.append(newButton)
    }

    return container
  }

  createButton(button) {
    const $button = document.createElement('button')
    $button.className = 'btn'
    $button.setAttribute('id', button.id)
    $button.dataset.button = JSON.stringify(button)

    // Label
    if (typeof button.label !== 'undefined') {
      $button.innerHTML = button.label
    }

    // title
    if (typeof button.title !== 'undefined') {
      $button.setAttribute('title', button.title)
    }

    // Css class
    if (typeof button.className !== 'undefined' && button.className.trim() !== '') {
      $button.classList.add(button.className)
    } else {
      $button.classList.add('btn-default')
    }

    // Data attributes
    if (typeof button.data === 'object' && button.data.constructor === {}.constructor) {
      const dataEntries = Object.entries(button.data)
      for (const value of dataEntries) {
        $button.setAttribute('data-' + value[0], value[1])
      }
    }

    // Button on click
    EventHandler.on($button, EVENT_CLICK, event => {
      if (typeof button.action === 'function') {
        return button.action.call($button, this._element, event)
      }
    })

    return $button
  }

  setElementContent(element, content) {
    if (element === null) {
      return
    }

    if (typeof content === 'object' && isElement(content)) {
      if (content.jquery) {
        content = content[0]
      }

      // content is a DOM node or a jQuery
      if (content.parentNode !== element) {
        element.innerHTML = ''
        element.append(content)
      }

      return
    }

    if (this._config.html) {
      if (this._config.sanitize) {
        content = sanitizeHtml(content, this._config.allowList, this._config.sanitizeFn)
      }

      element.setInnerHTML(content)
    } else {
      element.setInnerHTML(content)
    }
  }

  toggle() {
    return this._isShown ? this.dispose() : this.show(this._dialog)
  }

  show(relatedTarget) {
    if (this._isShown || this._isTransitioning) {
      return
    }

    if (this.ajaxContent && !this.ajaxLoaded) {
      return
    }

    if (this._element.classList.contains(CLASS_NAME_FADE)) {
      this._isTransitioning = true
    }

    const showEvent = EventHandler.trigger(this._element, EVENT_SHOW, {
      relatedTarget
    })

    if (this._isShown || showEvent.defaultPrevented) {
      return
    }

    this._isShown = true

    this._checkScrollbar()
    this._setScrollbar()

    this._adjustDialog()

    this._setEscapeEvent()
    this._setResizeEvent()

    EventHandler.on(this._dialog, EVENT_CLICK_DISMISS, SELECTOR_DATA_DISMISS, event => {
      this.dispose(event)
    })

    EventHandler.on(this._dialog, EVENT_MOUSEDOWN_DISMISS, () => {
      EventHandler.one(this._dialog, EVENT_MOUSEUP_DISMISS, event => {
        if (event.target === this._dialog) {
          this._ignoreBackdropClick = true
        }
      })
    })
    this._showBackdrop(() => this._showElement(relatedTarget))
  }

  _hideModal() {
    if (this._element) {
      this._element.style.display = 'none'
      this._element.setAttribute('aria-hidden', true)
      this._element.removeAttribute('aria-modal')
      this._element.removeAttribute('role')
      this._isTransitioning = false
      document.body.classList.remove(CLASS_NAME_OPEN)
      this._showBackdrop(() => {
        this._resetAdjustments()
        this._resetScrollbar()
        EventHandler.trigger(this._element, EVENT_HIDDEN)
      })
      this.dispose()
    }
  }

  /**
   * RFC4122 version 4 compliant unique id creator.
   *
   * Added by https://github.com/tufanbarisyildirim/
   *
   *  @returns {String}
   */
  static newGuid() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, c => {
      const r = Math.random() * 16 | 0
      const v = c === 'x' ? r : (r & 0x3 | 0x8)
      return v.toString(16)
    })
  }

  /* ================================================
   * For lazy people
   * ================================================ */

  /**
   * Shortcut function: show
   *
   * @param {type} options
   * @returns the created dialog instance
   */
  static show(options) {
    return new Dialog(options).open()
  }

  dispose() {
    for (const htmlElement of [window, this._element, this._dialog]) {
      EventHandler.off(htmlElement, EVENT_KEY)
    }

    /**
     * `document` has 2 events `EVENT_FOCUSIN` and `EVENT_CLICK_DATA_API`
     * Do not move `document` in `htmlElements` array
     * It will remove `EVENT_CLICK_DATA_API` event that should remain
     */
    EventHandler.off(document, EVENT_FOCUSIN)

    this._config = null
    if (this._dialog && this._dialog.parentNode) {
      this._dialog.remove()
    }

    this._dialog = null
    if (this._element && this._element.parentNode) {
      this._element.remove()
    }

    this._element = null
    if (this._backdrop && this._backdrop.parentNode) {
      this._backdrop.remove()
    }

    this._backdrop = null
    this._isShown = null
    this._isBodyOverflowing = null
    this._ignoreBackdropClick = null
    this._isTransitioning = null
    this._scrollbarWidth = null
    if (document.body.classList.contains('modal-open')) {
      document.body.classList.remove('modal-open')
    }
  }

  static createDialog(el, opts) {
    if (el && el.classList && el.classList.contains('.modal')) {
      return el
    }

    const element = document.createElement('div')
    element.innerHTML = opts.template
    const dialog = element.children[0]

    if (opts.extraClasses) {
      dialog.querySelector('.modal-dialog').classList.add(opts.extraClasses)
    }

    return dialog
  }

  /**
   * Alert window
   *
   * @returns the created dialog instance
   */
  static alert(el, config) {
    let alertOptions = {}
    const defaultAlertOptions = {
      type: 'alert',
      title: null,
      content: null,
      closable: false,
      draggable: false,
      buttons: [],
      buttonLabel: getPlaceholder('diary_js_ok') || 'OK',
      callback: null
    }

    alertOptions = typeof arguments[1] === 'object' && arguments[1].constructor === {}.constructor ?
      {
        ...this.Default,
        ...defaultAlertOptions,
        ...arguments[1]
      } :
      {
        ...this.Default,
        ...defaultAlertOptions,
        ...JSON.parse(arguments[1])
      }
    el = this.createDialog(el, alertOptions)
    const dialog = new Dialog(el, alertOptions)
    dialog.addButton({
      label: alertOptions.buttonLabel,
      data: { 'ff-dismiss': 'dialog' },
      action(popup, evt) {
        popup = evt.target.getclosest('.modal')
        if (popup) {
          const pop = Dialog.getInstance(popup)
          pop.dispose()
        }

        document.body.classList.remove('modal-open')
      }
    })
    dialog.updateButtons()
    return dialog.toggle(el)
  }

  /**
   * Confirm window
   *
   * @returns the created dialog instance
   */
  static confirm(el, config) {
    let confirmOptions = {}
    const defaultConfirmOptions = {
      type: 'confirm',
      title: null,
      content: null,
      closable: false,
      draggable: false,
      buttons: [],
      events: null,
      btnCancelLabel: getPlaceholder('diary_js_cancel') || 'Cancel',
      btnCancelClass: null,
      btnCancelCallback(popup, evt) {
        popup = evt.target.getclosest('.modal')
        if (popup) {
          const pop = Dialog.getInstance(popup)
          pop.dispose()
        }

        document.body.classList.remove('modal-open')
      },
      btnOKLabel: getPlaceholder('diary_js_ok') || 'OK',
      btnOKClass: null,
      btnOKCallback: null
    }
    confirmOptions = typeof arguments[1] === 'object' && arguments[1].constructor === {}.constructor ?
      {
        ...this.Default,
        ...defaultConfirmOptions,
        ...arguments[1]
      } :
      {
        ...this.Default,
        ...defaultConfirmOptions,
        ...JSON.parse(arguments[1])
      }

    if (confirmOptions.btnOKClass === null) {
      confirmOptions.btnOKClass = ['btn', confirmOptions.type.split('-')[1]].join('-')
    }

    el = this.createDialog(el, confirmOptions)
    const dialog = new Dialog(el, confirmOptions)
    const buttons = [{
      label: confirmOptions.btnCancelLabel,
      cssClass: confirmOptions.btnCancelClass,
      data: { 'ff-dismiss': 'dialog' },
      action: confirmOptions.btnCancelCallback
    },
    {
      label: confirmOptions.btnOKLabel,
      cssClass: confirmOptions.btnOKClass,
      action: confirmOptions.btnOKCallback
    }]

    if (!confirmOptions.buttons.length) {
      dialog.addButtons(buttons)
    }

    if (confirmOptions.events !== null) {
      if (confirmOptions.events.show) {
        EventHandler.on(dialog._element, EVENT_SHOW, confirmOptions.events.show)
      }

      if (confirmOptions.events.shown) {
        EventHandler.on(dialog._element, EVENT_SHOWN, confirmOptions.events.shown)
      }

      if (confirmOptions.events.hide) {
        EventHandler.on(dialog._element, EVENT_HIDE, confirmOptions.events.hide)
      }

      if (confirmOptions.events.hidden) {
        EventHandler.on(dialog._element, EVENT_HIDDEN, confirmOptions.events.hidden)
      }
    }

    let bobbins = dialog.updateButtons()
    return dialog.toggle(el)
  }

  /**
   * Warning window
   *
   * @param {type} message
   * @returns the created dialog instance
   */
  static warning(message, callback) {
    return new Dialog({
      type: Dialog.TYPE_WARNING,
      content: message
    }).open()
  }

  /**
   * Danger window
   *
   * @param {type} message
   * @returns the created dialog instance
   */
  static danger(message, callback) {
    return new Dialog({
      type: Dialog.TYPE_DANGER,
      content: message
    }).open()
  }

  /**
   * Success window
   *
   * @param {type} message
   * @returns the created dialog instance
   */
  static success(message, callback) {
    return new Dialog({
      type: Dialog.TYPE_SUCCESS,
      content: message
    }).open()
  }

  static jQueryInterface(config, relatedTarget) {
    return this.each(function () {
      let data = Data.getData(this, DATA_KEY)
      const _config = {
        ...Default,
        ...Manipulator.getDataAttributes(this),
        ...(typeof config === 'object' && config ? config : {})
      }

      if (!data) {
        data = new Dialog(this, _config)
      }

      if (typeof config === 'string') {
        if (typeof data[config] === 'undefined') {
          throw new TypeError(`No method named "${config}"`)
        }

        data[config](relatedTarget)
      }
    })
  }
}

EventHandler.on(document, EVENT_CLICK_DATA_API, SELECTOR_DATA_TOGGLE, function (event) {
  const target = getElementFromSelector(this)

  if (this.tagName === 'A' || (this.parentNode && this.parentNode.tagName === 'A') || this.tagName === 'AREA') {
    event.preventDefault()
  }

  EventHandler.one(target, EVENT_SHOW, showEvent => {
    if (showEvent.defaultPrevented) {
      // only register focus restorer if modal will actually get shown
      return
    }

    EventHandler.one(target, EVENT_HIDDEN, () => {
      if (isVisible(this)) {
        this.focus()
      }
    })
  })

  let data = Data.getData(target, DATA_KEY)
  if (!data) {
    const config = {
      ...Manipulator.getDataAttributes(target),
      ...Manipulator.getDataAttributes(this)
    }
    data = new Dialog(target, config)
  }

  data.toggle(this)
})

/**
 * ------------------------------------------------------------------------
 * jQuery
 * ------------------------------------------------------------------------
 * add .Dialog to jQuery only if jQuery is present
 */

defineJQueryPlugin(NAME, Dialog)

export default Dialog

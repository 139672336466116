
import Dialog from './dialog'
import EventHandler from './dom/event-handler'
import Manipulator from './dom/manipulator'
import api from './api'
import Breadcrumbs from './breadcrumbs'
import Search from './search'
import OccDetails from './occdetails'
import AutoComplete from './autocomplete2'
import { isMobile, getPlaceholder } from './util'

const NAME = 'action'
const DATA_KEY = 'ff.action'
const EVENT_KEY = `.${DATA_KEY}`
const DATA_API_KEY = '.data-api'

const EVENT_CLICK_DATA_API = `click${EVENT_KEY}${DATA_API_KEY}`

const SELECTOR_DATA_TOGGLE = '[data-ff-toggle="action"]'

class Actions {
  static to_dish_ingredients(evTrgt) {
    window.ff.search.levels = 0
    window.ff.search.mode = 2
    window.ff.el.productMenu.hide()
    window.ff.el.dishMenu.show()
  }

  static add_dish(evTrgt, dish) {
    let callback = function () {}
    const url = new URL(window.location.href)
    const SES = url.searchParams.get('SES') ? url.searchParams.get('SES') : ''

    try {
      if (typeof dish === 'string') {
        dish = JSON.parse(dish)
      }
    } catch (_) {}

    if ((Number.isNaN(dish.dish_id) || dish.dish_id < 1) && dish.label.length < 3) {
      Dialog.alert(evTrgt, { content: getPlaceholder('diary_js_empty_dish_message') })
      return false
    }

    window.ff.el.addProducts.hide()
    window.ff.el.dishContent.innerHTML = ''
    window.ff.el.productContent.innerHTML = ''
    Search.resetSearch()
    AutoComplete.resetSearch()
    window.ff.usedProductCategories = []
    window.ff.currentdish = dish
    OccDetails.set_is_dish(true)
    Breadcrumbs.remove()
    Breadcrumbs.add(dish.label)
    Breadcrumbs.add(getPlaceholder('diary_js_breadcrumb_adding_ingredients'))
    var prepared = false 
    try {
      prepared = (window.ff.answers.whocooked.selected.toString().indexOf("1") > -1)
    } catch (e) {
    }
    api.post(
      window.ff.pages.ajax,
      {
        act: 'add_dish',
        occasion_id: window.ff.occasion_id,
        usage_course_id: OccDetails.get_usage_course_id(),
        dish_id: dish.dish_id,
        other_dish_id: dish.other_dish_id,
        label: dish.label,
        SES
      },
      true
    ).then(data => {
      window.ff.currentdish.usage_dish_id = data.trim()

      const [eatingOut, eatingDelivery] = OccDetails.getAnswers()
      if (eatingOut > 0 || eatingDelivery > 0 || !prepared || window.ff.allDishesEmpty == 1) {
        this.complete_dish(evTrgt)
        Breadcrumbs.remove()
        OccDetails.showSelectedItems()
        window.ff.currentdish.usage_dish_id = null
      } else {  
        window.ff.el.productSearch.show()
        for (let y = 0; y < [window.ff.el.dishSearch, window.ff.el.addDish].length; y++) {
          const el = [window.ff.el.dishSearch, window.ff.el.addDish][y]
          el.hide()
        }
        OccDetails.showSelectedItems()
        OccDetails.show_favorite_ingredients()
      }
    })

    const [eatingOut, eatingDelivery] = OccDetails.getAnswers()
    //if (dish.dish_id > 0 || eatingOut > 0 || eatingDelivery > 0 || !prepared) {
      if (dish.dish_id > 0  && prepared && window.ff.allDishesEmpty !== 1) {  
      OccDetails.showDishmenu(dish.dish_id, window.ff)
    } else {
      OccDetails.toggleView()
      window.ff.el.productSearch.show();
    }
  }

  static edit_dish(evTrgt, thisDish) {
    if (typeof thisDish === 'string') {
      thisDish = JSON.parse(thisDish)
    }

    if (window.ff.currentdish.usage_dish_id > 0) {
      return
    }

    var prepared = false 
    try {
      prepared = (window.ff.answers.whocooked.selected.toString().indexOf("1") > -1)
    } catch (e) {
    }
    const [eatingOut, eatingDelivery] = OccDetails.getAnswers()
    if ((eatingOut > 0 || eatingDelivery > 0) || !prepared) {
      return
    }

    window.ff.el.addProducts.hide()
    window.ff.currentdish = thisDish
    window.ff.el.dishContent.innerHTML = ''
    window.ff.el.productContent.innerHTML = ''
    window.ff.usedProductCategories = []
    window.ff.el.productSearch.show()
    window.ff.el.dishSearch.hide()
    Search.resetSearch()
    OccDetails.set_is_dish(true)
    Breadcrumbs.remove()
    Breadcrumbs.add(thisDish.label)
    Breadcrumbs.add(getPlaceholder('diary_js_breadcrumb_adding_ingredients'))
    OccDetails.showSelectedItems()
    OccDetails.show_favorite_ingredients()
    if (thisDish.usage_dish_id > 0) {
      OccDetails.showDishmenu(thisDish.usage_dish_id, ff)
    } else {
      window.ff.el.productMenu.show()
      window.ff.el.dishMenu.hide()
      window.ff.el.toIngredients.hide()
    }
  }

  static add_product(evTrgt, product_data) {
    const url = new URL(window.location.href)
    const SES = url.searchParams.get('SES') ? url.searchParams.get('SES') : ''
    const products = JSON.parse(product_data);

    //window.ff.el.addProducts.hide()

    api.post(
      window.ff.pages.ajax,
      {
        act: 'add_product',
        product_id: products.product_id,
        usage_course_id: OccDetails.get_usage_course_id(),
        other_product_id: products.other_product_id,
        usage_dish_id: ff.currentdish.usage_dish_id,
        SES
      },
      true
    ).then(data => OccDetails.showSelectedItems())
  }

  static add_other_product(evTrgt) {
    const url = new URL(window.location.href)
    const SES = url.searchParams.get('SES') ? url.searchParams.get('SES') : ''

    if (document.getElementById('product_label').value.length < 3) {
      Dialog.alert(evTrgt, { content: getPlaceholder('diary_js_empty_product_message') })
      return false
    }

    api.post(
      window.ff.pages.ajax,
      {
        act: 'add_other_product',
        product_label: document.getElementById('product_label').value,
        categories: document.getElementById('product_categories').value,
        usage_dish_id: window.ff.currentdish.usage_dish_id,
        usage_course_id: OccDetails.get_usage_course_id(),
        SES
      },
      true
    ).then(data => OccDetails.showSelectedItems())
  }

  static delete_product(evTrgt, usage_product_id) {
    const url = new URL(window.location.href)
    const SES = url.searchParams.get('SES') ? url.searchParams.get('SES') : ''
    let targetProduct = document.querySelector('.deleteicon[data-ff-data="' + usage_product_id +'"]').parentElement.textContent
    Dialog.confirm(
      evTrgt,
      {
        content: getPlaceholder('diary_js_confirm_delete_product') + ' ' + targetProduct ,
        btnOKLabel: getPlaceholder('diary_ok_btn') || 'ok',
        btnCancelLabel: getPlaceholder('diary_cancel_btn') || 'cancel',
        btnOKCallback: (dialog, evt) => {
          let popup = dialog
          api.post(
            window.ff.pages.ajax,
            {
              act: 'delete_product',
              usage_product_id,
              edit: window.ff.edit,
              SES
            },
            true
          ).then(
            data => {
              OccDetails.showSelectedItems()
              const pop = Dialog.getInstance(popup)
              pop.hide(evt)
            }
          )
          return true
        }
      }
    )
  }

  static delete_dish(evTrgt, usage_dish_id) {
    const url = new URL(window.location.href)
    const SES = url.searchParams.get('SES') ? url.searchParams.get('SES') : ''

    Dialog.confirm(
      evTrgt,
      {
        content: getPlaceholder('diary_js_confirm_delete_dish'),
        btnOKLabel: getPlaceholder('diary_ok_btn') || 'ok',
        btnCancelLabel: getPlaceholder('diary_cancel_btn') || 'cancel',
        btnOKCallback: (popup, evt) => {
          document.getElementById('searchdishes').value = ''
          if (usage_dish_id == window.ff.currentdish.usage_dish_id) {
            window.ff.currentdish = {}
            Breadcrumbs.remove()
            Search.resetSearch()
            OccDetails.show_dish_view()
          }

          api.post(
            window.ff.pages.ajax,
            {
              act: 'delete_dish',
              occasion_id: window.ff.occasion_id,
              usage_dish_id,
              edit: window.ff.edit,
              SES
            },
            true
          ).then(_ => {
            OccDetails.showSelectedItems()
            const pop = Dialog.getInstance(popup)
            pop.hide(evt)
          })
          return true
        }
      }
    )
  }

  static delete_course(evTrgt, usage_course_id) {
    const url = new URL(window.location.href)
    const SES = url.searchParams.get('SES') ? url.searchParams.get('SES') : ''

    Dialog.confirm(
      evTrgt,
      {
        content: getPlaceholder('diary_js_confirm_delete_course'),
        btnOKLabel: getPlaceholder('diary_ok_btn') || 'ok',
        btnCancelLabel: getPlaceholder('diary_cancel_btn') || 'cancel',
        btnOKCallback: (popup, evt) => {
          if (usage_course_id == OccDetails.get_usage_course_id()) {
            window.ff.currentdish = {}
            window.ff.previous_course()
            window.ff.el.dishContent.innerHTML = ''
            window.ff.el.productContent.innerHTML = ''
            //ff.el.productContent.innerHTML = lang.diary_js_product_content
          }

          api.post(
            window.ff.pages.ajax,
            {
              act: 'delete_course',
              occasion_id: window.ff.occasion_id,
              usage_dish_id: usage_course_id,
              edit: window.ff.edit,
              SES
            },
            true
          ).then(_ => {
            OccDetails.showSelectedItems()
            const pop = Dialog.getInstance(popup)
            pop.hide(evt)
          })
          return true
        }
      }
    )
  }

  static complete_dish(evTrgt) {
    const url = new URL(window.location.href)
    const SES = url.searchParams.get('SES') ? url.searchParams.get('SES') : ''

    document.getElementById('searchdishes').value = ''
    window.ff.usedProductCategories = []
    Breadcrumbs.remove()

    api.post(
      window.ff.pages.ajax,
      {
        act: 'complete_dish',
        occasion_id: window.ff.occasion_id,
        usage_dish_id: window.ff.currentdish.usage_dish_id,
        SES
      },
      true
    ).then(data => {
      var prepared = false 
      try {
        prepared = (window.ff.answers.whocooked.selected.toString().indexOf("1") > -1)
      } catch (e) {
      }
      
      //Search.resetSearch()
      //OccDetails.show_dish_view()
      if (data.includes('true') || !prepared || window.ff.allDishesEmpty == 1) {
        if (!prepared || window.ff.allDishesEmpty == 1) OccDetails.show_product_view() 
        else OccDetails.show_dish_view()
        if (isMobile) {
          const oldDialog = evTrgt.closest('.modal')
          if (oldDialog) {
            const modal = Dialog.getInstance(oldDialog)
            modal.dispose()
          }
        }
      } else {
        const [eatingOut, eatingDelivery] = OccDetails.getAnswers()
        if (eatingOut == 0 && eatingDelivery == 0 && prepared && window.ff.allDishesEmpty == 0) {        
          Dialog.confirm(
            evTrgt,
            {
              content: getPlaceholder('diary_js_confirm_dish_validation') +
                          '<ul><li>' + JSON.parse(data).join('</li><li>') + '</li></ul>',
              btnOKLabel: getPlaceholder('diary_confirm_btn') || 'ok',
              btnCancelLabel: getPlaceholder('diary_dont_confirm_btn') || 'cancel',
              btnOKCallback: (popup, evt) => {
                const pop = Dialog.getInstance(popup)
                pop.hide(evt)
                if (!prepared || window.ff.allDishesEmpty == 1) OccDetails.show_product_view() 
                else OccDetails.show_dish_view()
                if (isMobile) {
                  const oldDialog = evTrgt.closest('.modal')
                  if (oldDialog) {
                    const modal = Dialog.getInstance(oldDialog)
                    modal.dispose()
                  }
                }
                //OccDetails.show_dish_view()
              }
            }
          )
        }
        
      }
    })
    Search.resetSearch()
  }

  static next(evTrgt, what) {
    let callback = function () {}
    switch (what) {
      case 'dish':
        callback = OccDetails.show_dish_view
        break
      case 'product':
        callback = OccDetails.show_product_view
        break
      default:
    }

    if (window.ff.currentdish.usage_dish_id) {
      this.constructor.complete_dish(callback)
    } else {
      callback()
    }
  }

  static confirm_cancel(evTrgt, link) {
    const url = new URL(window.location.href)
    const SES = url.searchParams.get('SES') ? url.searchParams.get('SES') : ''

    Dialog.confirm(
      evTrgt,
      {
        content: getPlaceholder('diary_js_confirm_cancel_occasion'),
        btnOKLabel: getPlaceholder('diary_ok_btn') || 'ok',
        btnCancelLabel: getPlaceholder('diary_cancel_btn') || 'cancel',
        btnOKCallback: (popup, evt) => {
          api.post(
            window.ff.pages.ajax,
            {
              act: 'delete_occasion',
              occasion_id: window.ff.occasion_id,
              SES
            },
            true
          ).then(_ => document.location.href = window.ff.pages.day)
          return true
        }
      }
    )
    return false
  }
}

EventHandler.on(document, EVENT_CLICK_DATA_API, SELECTOR_DATA_TOGGLE, event => {
  let trgt = event.target
  let attribs = Manipulator.getDataAttributes(trgt)

  if (trgt.tagName === 'A' || (trgt.parentNode && trgt.parentNode.tagName === 'A') || trgt.tagName === 'AREA') {
    event.preventDefault()
  }

  if (!trgt || !attribs.hasOwnProperty('action')) {
    trgt = event.target.closest(SELECTOR_DATA_TOGGLE)
    attribs = Manipulator.getDataAttributes(trgt)
  }

  Actions[attribs.action](trgt, (attribs.hasOwnProperty('data') ? attribs.data : {}))
})

export default Actions

import Dialog from './dialog'
import EventHandler from './dom/event-handler'
import Help from './help'
import SelectorEngine from './dom/selector-engine'
import { isMobile, getPlaceholder } from './util'

const NAME = 'ff.dayoverfview'
const DATA_KEY = 'ff.dayoverfview'
const DATA_API_KEY = '.data-api'
const EVENT_KEY = `.${DATA_KEY}`

const EVENT_CLICK = `click${EVENT_KEY}`
const EVENT_SUBMIT = `submit${EVENT_KEY}`
const EVENT_CLICK_DATA_API = `click${EVENT_KEY}${DATA_API_KEY}`
const EVENT_LOAD_DATA_API = `load${EVENT_KEY}${DATA_API_KEY}`

const Default = {
  attributes: {
    values: {
      ate: 1,
      lunchbox: 2,
      not: 3,
      out: 4,
      delivery: 5
    }
  }
}

class DayOverview {
  constructor(config) {
    this.helpTopic = 'diary_overview'
    this.config = config
    this.el = {}
    this.el.daystarts = document.querySelectorAll('.days-sub button')
    this.el.daycompletes = document.querySelectorAll('.days-comp button')
    this.el.forms = document.querySelectorAll('#occasions form')
    Help.init({ topic: this.helpTopic })
    if (isMobile) {
      document.body.classList.add('mobile')
    }
    if (isMobile && document.querySelectorAll('.days[role="tablist]').length === 0) {
      document.getElementById('occasions').classList.add('tab-clicked')
    }
    for (const daystart of this.el.daystarts) {
      EventHandler.on(daystart, EVENT_CLICK, event => {
        event.preventDefault()
        let par = SelectorEngine.parents(event.target, 'div')[0]
        let continueLess = true
        if (par.classList.contains('days-sub')) {
          const categories = SelectorEngine.parents(event.target, 'form')[0].querySelectorAll('input[type=checkbox]')
          let chosen = 0
          for (const check of categories) {
            if (check.checked) chosen++
          }
          let orOccasions = SelectorEngine.parents(event.target, 'form')[0].querySelectorAll('[type=hidden][name=or_occasions]')
          // Filtering only the "or_occasions" with a value
          orOccasions = [...orOccasions].filter(input => input.value !== '');
          if (chosen < 1 && orOccasions.length > 0 ) {
            Dialog.alert(daystart, { content: getPlaceholder('diary_js_invalid_brand_occasions') })
          } else if (chosen < categories.length && orOccasions.length <= 0) { 
            const tabs = SelectorEngine.parents(event.target, 'form')[0].querySelectorAll('.tab-pane')
            
            for (const tab of tabs) {
              let catTabs = tab.querySelectorAll('input[type=checkbox]:checked')
          
              if (catTabs.length == 0) {
                continueLess = false
              }
  
            }
            if (continueLess == true) {
              DayOverview.startDay(daystart)
            } else {  
              Dialog.alert(daystart, { content: getPlaceholder('diary_js_invalid_complete_day') })
            }
          }
          else SelectorEngine.parents(event.target, 'form')[0].submit()
        } else if (par.classList.contains('days-comp')) {
          const dtUsed = SelectorEngine.parents(event.target, 'form')[0].querySelector('[name=dt_used]').value
          DayOverview.complete_day(this, dtUsed)
        }
      })
    }  
    for (const daycomplete of this.el.daycompletes) {
      EventHandler.on(daycomplete, EVENT_CLICK, event => {
        event.preventDefault()
        const dtUsed = SelectorEngine.parents(event.target, 'form')[0].querySelector('[name=dt_used]').value
        DayOverview.complete_day(this, dtUsed)
      })
    }
    for (const thisform of this.el.forms) {
      EventHandler.on(thisform, EVENT_SUBMIT, event => {
        event.preventDefault()
        let par = SelectorEngine.parents(event.target, 'div')[0]
        let continueLess = true
        if (par.classList.contains('days-sub')) {
          const categories = SelectorEngine.parents(event.target, 'form')[0].querySelectorAll('input[type=checkbox]')
          let chosen = 0
          for (const check of categories) {
            if (check.checked) chosen++
          }
          if (chosen < categories) { 
            const tabs = SelectorEngine.parents(event.target, 'form')[0].querySelectorAll('.tab-pane')
            
            for (const tab of tabs) {
              let catTabs = tab.querySelectorAll('input[type=checkbox]:checked')
          
              if (catTabs.length == 0) {
                continueLess = false
                Dialog.alert(thisform, { content: getPlaceholder('diary_js_invalid_complete_day') })
              }
  
            }
            if (continueLess == true) {
              DayOverview.startDay(thisform)
            }  
          }
          else SelectorEngine.parents(event.target, 'form')[0].submit()
        } else if (par.classList.contains('days-comp')) {
          const dtUsed = SelectorEngine.parents(event.target, 'form')[0].querySelector('[name=dt_used]').value
          DayOverview.complete_day(this, dtUsed)
        }
      })
    }
  }


  // Getters

  static get Default() {
    return Default
  }

  static create(options) {
    return new DayOverview(options)
  }

  static startDay(evnt) {
    const thisStartItem = evnt
    Dialog.confirm(evnt,
      {
        content: getPlaceholder('diary_js_alert_all_cats'),
        buttons: [
          {
            label: getPlaceholder('diary_js_cancel') || 'Cancel',
            data: { 'ff-dismiss': 'dialog' }
          },
          {
            label: getPlaceholder('diary_js_continue') || 'Ok',
            action: ev => {
              SelectorEngine.parents(thisStartItem, 'form')[0].submit()
            }
          }
        ]
      })
  }

  static complete_day(ev, completeDay) {
    const url = new URL(window.location.href)
    const SES = url.searchParams.get('SES') ? url.searchParams.get('SES') : ''

    // Dialog.confirm(null,{
    Dialog.confirm(ev, {
      extraClasses: 'modal-lg',
      content: {
        url: ff.pages.ajax,
        options: {
          parameters: {
            act: 'complete_day',
            date: completeDay,
            SES
          }
        }
      },
      buttons: [
        {
          label: getPlaceholder('diary_js_cancel') || 'Cancel',
          data: { 'ff-dismiss': 'dialog' }
        },
        {
          label: getPlaceholder('diary_js_ok') || 'Ok',
          action: ev => {
            DayOverview.submit(ev)
          }
        }
      ]
    })
  }

  static complete_week(ev) {
    Dialog.confirm(ev,
      {
        content: getPlaceholder('diary_js_complete_week_confirm'),
        btnOKLabel: getPlaceholder('diary_js_ok') || 'OK',
        btnOKClass: null,
        btnOKCallback: () => {
          document.getElementById('complete_week').submit()
        }
      })
  }

  static submit(win) {
    const confirm_form = document.querySelector('form#confirm')
    let valid = true
    const inputs = confirm_form.querySelectorAll('input[type=radio]')
    let clicked = true
    let current = ''

    for (let i = 0; i < inputs.length && valid; i++) {
      if (current != inputs[i].name) {
        if (!clicked) {
          valid = false
        }

        clicked = false
        current = inputs[i].name
      }

      if (inputs[i].checked) {
        clicked = true
        //console.log(i + ' ' + inputs[i].value + ' ' + Default.attributes.values.ate + ' ' + Default.attributes.values.lunchbox)
        if (inputs[i].value == Default.attributes.values.ate || inputs[i].value == Default.attributes.values.lunchbox) {
          valid = false
        }
      }
    }

    if (valid) {
      confirm_form.submit()
    } else {
      const evt = new MouseEvent('click', {
        bubbles: true,
        cancelable: true,
        view: window
      })
      // If cancelled, don't dispatch our event
      const canceled = !win.querySelector('[data-ff-dismiss="dialog"]').dispatchEvent(evt)
      // Dialog.alert(document.querySelector('.tab-pane.active'), {content: getPlaceholder('diary_js_invalid_complete_day')});
      Dialog.alert(evt, { content: getPlaceholder('diary_js_invalid_complete_day') })
    }

    return valid
  }
}

export default DayOverview

import {
  getElementFromSelector,
  isVisible,
  isElement,
  getPlaceholder
} from './util/index'
import Data from './dom/data'
import Modal from './modal'
import EventHandler from './dom/event-handler'
import Manipulator from './dom/manipulator'
import SelectorEngine from './dom/selector-engine'
import { sanitizeHtml } from './util/sanitizer'

const NAME = 'help'
const DATA_KEY = 'ff.help'
const EVENT_KEY = `.${DATA_KEY}`
const DATA_API_KEY = '.data-api'

const HELP_ON = '.help_topic'
const HELP_ICON = '.helpicon'

const CLASS_NAME_FADE = 'in'

const EVENT_HIDDEN = `hidden${EVENT_KEY}`
const EVENT_SHOW = `show${EVENT_KEY}`
const EVENT_CLICK_DISMISS = `click.dismiss${EVENT_KEY}`
const EVENT_MOUSEUP_DISMISS = `mouseup.dismiss${EVENT_KEY}`
const EVENT_MOUSEDOWN_DISMISS = `mousedown.dismiss${EVENT_KEY}`
const EVENT_CLICK_DATA_API = `click${EVENT_KEY}${DATA_API_KEY}`

const EVENT_MOUSEUP = `mouseup${EVENT_KEY}`
const EVENT_MOUSEDOWN = `mousedown${EVENT_KEY}`
const EVENT_MOUSEOVER = `mouseover${EVENT_KEY}`
const EVENT_MOUSEMOVE = `mousemove${EVENT_KEY}`

const SELECTOR_TITLE = '.help-header h4'
const SELECTOR_DIALOG = '.help-dialog'
const SELECTOR_MODAL_BODY = '.help-body'
const SELECTOR_DATA_TOGGLE = '[data-ff-toggle="help"]'
const SELECTOR_DATA_DISMISS = '[data-ff-dismiss="help"]'
const SELECTOR_FIXED_CONTENT = '.fixed-top, .fixed-bottom, .is-fixed, .sticky-top'
const SELECTOR_STICKY_CONTENT = '.sticky-top'


const Default = {
  ...Modal.Default,
  buttons: null,
  topic: 'start',
  cssName: HELP_ICON,
  cssTopic: HELP_ON,
  template: '<div class="help blue-box" id="help" tabindex="-1">' +
        '<div class="help-dialog">' +
        '<div class="help-content">' +
        '<div class="help-header"><a data-ff-dismiss="help">X</a><h4></h4></div>' +
        '<div class="help-body"></div>' +
        '</div>' +
        '</div>' +
        '</div>'
}

class Help extends Modal {
  constructor(element, config) {
    super(element)

    this.config = this._getConfig(config)
    this._element = this.getDialogElement()
    this._dialog = SelectorEngine.findOne(SELECTOR_DIALOG, this._element)

    this.active = false
    if (this.config.topic) {
      this.current = this.config.topic
    }
    else {
      this.current = ''
    }

    this.storageEnd = null
    for (const icon of document.querySelectorAll(this.config.cssName)) {
      icon.hide()
    }

    document.querySelector(SELECTOR_DATA_TOGGLE).parentNode.insertBefore(this._element, document.querySelector(SELECTOR_DATA_TOGGLE).nextSibling)
    if (localStorage.getItem('ff_help_position')) {
      try {
        const newPosn = JSON.parse(localStorage.getItem('ff_help_position'))

        Object.assign(
          this._element.style,
          {
            position: 'absolute',
            top: newPosn.top,
            left: newPosn.left
          }
        )
      } catch (error) {}
    }

    this.activate()
  }

  // Getters
  static get Default() {
    return Default
  }

  static get NAME() {
    return NAME
  }

  static get DATA_KEY() {
    return DATA_KEY
  }

  static get Event() {
    return Event
  }

  static get EVENT_KEY() {
    return EVENT_KEY
  }

  static get DefaultType() {
    return DefaultType
  }

  _getConfig(config) {
    const dataAttributes = Manipulator.getDataAttributes(this._element)
    config = {
      ...this.constructor.Default,
      ...dataAttributes,
      ...(typeof config === 'object' && config ? config : {})
    }

    if (config.sanitize) {
      config.template = sanitizeHtml(config.template, config.allowList, config.sanitizeFn)
    }

    return config
  }

  getDialogElement() {
    if (this._dialog) {
      return this._dialog
    }

    const element = document.createElement('div')
    element.innerHTML = this.config.template

    this._dialog = element.children[0]
    return this._dialog
  }

  toggle() {
    if (this._isShown) {
      this.active = false
      for (const icon of document.querySelectorAll(this.config.cssName)) {
        icon.hide()
      }

      document.body.classList.remove('helpshown')

      this.hide()
    } else {
      this.activate()
    }
  }

  activate() {
    this.active = true
    if (Number.parseInt(localStorage.getItem('ff_help_closed'), 10) !== 1) {
      for (const icon of document.querySelectorAll(this.config.cssName)) {
        icon.show()
      }

      document.body.classList.add('helpshown')
    }

    this.ask()
    if (Number.parseInt(localStorage.getItem('ff_help_closed'), 10) !== 1) {
      this.show(this._dialog)
    }

    this.refreshObservers()
  }

  deactivate() {
    this.active = false
    for (const icon of document.querySelectorAll(this.config.cssName)) {
      icon.hide()
    }

    document.body.classList.remove('helpshown')

    localStorage.setItem('ff_help_closed', 1)
  }

  ask(topic, topic2, event) {
    if (!this.active) {
      return true
    }

    if (!getPlaceholder('diary_js_help_' + topic + '_question')) {
      topic = topic2
    }

    if (event && topic) {
      event.stop()
    }

    if (!getPlaceholder('diary_js_help_' + topic + '_question')) {
      topic = this.config.topic
    }

    const message = {
      question: getPlaceholder('diary_js_help_' + topic + '_question'),
      answer: getPlaceholder('diary_js_help_' + topic + '_answer')
    }
    if (message.answer) {
      if (!message.answer.startsWith('<p>')) {
        message.answer = '<p>' + message.answer
      }

      if (!message.answer.endsWith('</p>')) {
        message.answer += '</p>'
      }

      this.setElementContent(SelectorEngine.findOne(SELECTOR_MODAL_BODY, this._dialog), message.answer)
    }

    if (message.question) {
      this.setElementContent(SelectorEngine.findOne(SELECTOR_TITLE, this._dialog), message.question)
    }

    this.current = topic
    if (Number.parseInt(localStorage.getItem('ff_help_closed'), 10) !== 1) {
      this.show(this._dialog)
    }

    return false
  }

  _setScrollbar() {
    if (this._isBodyOverflowing) {
      this._setElementAttributes(SELECTOR_FIXED_CONTENT, 'paddingRight', calculatedValue => calculatedValue + this._scrollbarWidth)
      this._setElementAttributes(SELECTOR_STICKY_CONTENT, 'marginRight', calculatedValue => calculatedValue - this._scrollbarWidth)
      this._setElementAttributes('body', 'paddingRight', calculatedValue => calculatedValue + this._scrollbarWidth)
    }

    // document.body.classList.add(CLASS_NAME_OPEN)
  }

  show(relatedTarget) {
    if (this._isShown || this._isTransitioning) {
      return
    }

    if (this._element.classList.contains(CLASS_NAME_FADE)) {
      this._isTransitioning = true
    }

    const showEvent = EventHandler.trigger(this._element, EVENT_SHOW, {
      relatedTarget
    })

    if (this._isShown || showEvent.defaultPrevented) {
      return
    }

    this._isShown = true

    this._checkScrollbar()
    this._setScrollbar()

    this._adjustDialog()

    this._setEscapeEvent()
    this._setResizeEvent()

    EventHandler.on(this._element, EVENT_CLICK_DISMISS, SELECTOR_DATA_DISMISS, event => {
      this.deactivate()
      this.hide(event)
    })

    EventHandler.on(this._dialog, EVENT_MOUSEDOWN_DISMISS, () => {
      EventHandler.one(this._element, EVENT_MOUSEUP_DISMISS, event => {
        if (event.target === this._element) {
          this._ignoreBackdropClick = true
        }
      })
    })

    this._showElement(relatedTarget)
  }

  setElementContent(element, content) {
    if (element === null) {
      return
    }

    if (typeof content === 'object' && isElement(content)) {
      if (content.jquery) {
        content = content[0]
      }

      // content is a DOM node or a jQuery
      if (content.parentNode !== element) {
        element.innerHTML = ''
        element.append(content)
      }

      return
    }

    element.innerHTML = content
  }

  refreshObservers() {
    if (!this.active) {
      return
    }
    // console.log($$("." + tns.ff.help.cssName));

    EventHandler.on(this._element, EVENT_MOUSEDOWN, ev => {
      this._element.classList.add('draggable')
      this._element.innerX = ev.clientX + window.pageXOffset - this._element.offsetLeft
      this._element.innerY = ev.clientY + window.pageYOffset - this._element.offsetTop
      EventHandler.on(this._element, EVENT_MOUSEMOVE, e => {
        Object.assign(
          this._element.style,
          {
            position: 'absolute',
            width: Math.floor(this._element.offsetWidth) + 'px',
            height: Math.floor(this._element.offsetHeight) + 'px',
            top: Math.floor(e.clientY + window.pageYOffset - this._element.innerY) + 'px',
            left: Math.floor(e.clientX + window.pageXOffset - this._element.innerX) + 'px'
          }
        )
        localStorage.setItem('ff_help_position',
          JSON.stringify({
            top: Math.floor(e.clientY + window.pageYOffset - this._element.innerY) + 'px',
            left: Math.floor(e.clientX + window.pageXOffset - this._element.innerX) + 'px'
          })
        )
      })
      ev.preventDefault()
    })

    EventHandler.on(this._element, EVENT_MOUSEUP, () => {
      this._element.classList.remove('draggable')
      EventHandler.off(this._element, EVENT_MOUSEMOVE)
    })

    for (const helpItem of document.querySelectorAll(this.config.cssName)) {
      EventHandler.on(helpItem, EVENT_MOUSEOVER, event => {
        this.ask(event.target.id, event.target.name)
      })
    }

    for (const helpItem of document.querySelectorAll(this.config.cssTopic)) {
      EventHandler.on(helpItem, EVENT_MOUSEOVER, event => {
        this.ask(event.target.id, event.target.name)
      })
    }
  }

  static init(options) {
    if (document.querySelector(SELECTOR_DATA_TOGGLE)) {
      const hlp = new Help(document.querySelector(SELECTOR_DATA_TOGGLE), options)
    } else {
      return false
    }
  }
}

EventHandler.on(document, EVENT_CLICK_DATA_API, SELECTOR_DATA_TOGGLE, function (event) {
  let target = getElementFromSelector(this)

  if (this.tagName === 'A' || (this.parentNode && this.parentNode.tagName === 'A') || this.tagName === 'AREA') {
    event.preventDefault()
  }

  if (!target) {
    target = event.target.closest(SELECTOR_DATA_TOGGLE)
  }

  EventHandler.one(target, EVENT_SHOW, showEvent => {
    if (showEvent.defaultPrevented) {
      // only register focus restorer if modal will actually get shown
      return
    }

    EventHandler.one(target, EVENT_HIDDEN, () => {
      if (isVisible(this)) {
        this.focus()
      }
    })
  })

  let data = Data.getData(target, DATA_KEY)
  if (!data) {
    const config = {
      ...Manipulator.getDataAttributes(target),
      ...Manipulator.getDataAttributes(this)
    }
    data = new Help(target, config)
  }

  localStorage.setItem('ff_help_closed', 0)
  data.toggle()
})

export default Help


import EventHandler from './dom/event-handler'
import api from './api'
import Search from './search'
import SelectorEngine from './dom/selector-engine'
import OccDetails from './occdetails'
import { isMobile } from './util'

const NAME = 'producttree'
const DATA_KEY = 'ff.producttree'
const EVENT_KEY = `.${DATA_KEY}`

const EVENT_CHANGE = `change${EVENT_KEY}`
const EVENT_CLICK = `click${EVENT_KEY}`
const EVENT_BLUR = `blur${EVENT_KEY}`
const EVENT_FOCUS = `focusin${EVENT_KEY}`
const EVENT_KEYDOWN = `keydown${EVENT_KEY}`

class ProductTree {
  constructor(parent, id, type, data, structure) {
    this.type = type || ('_root' + ((isMobile) ? ' mobile' : ''))
    this.clicked = []
    this.types = {
      category: {
        prependParentId: '_'
        /* ,
        onClick() {
          return false
        } */
      },
      subcategory: {
        page: window.ff.pages.ajax,
        callback() {
          if (!ff.search.levels) {
            document.getElementById('category').removeAttribute('disabled')
            document.getElementById('category').setAttribute('checked', 'checked')
          }

          OccDetails.browseHierarchy(this.element.dataset.id, this.span.innerHTML, true)
          
          this.hideBranches()
          const oldParents = document.querySelectorAll('.category > .subcatcurrent')
          for (const el of oldParents) {
            el.classList.remove('subcatcurrent')
          }

          if (!document.getElementById(this.element.id).classList.contains('subcatcurrent')) {
            document.getElementById(this.element.id).classList.add('subcatcurrent')
          }

          Search.setCategory(this.element.id, this.span.innerHTML)
          return {
            act: 'get_tree_subcat',
            treenode: this.element.dataset.id,
            occasion_id: ff.occasion_id
          }
        }
      },
      product: {
        leafNode: true,
        insertion(el, data) {
          el.innerHTML = data
          this.clickProduct = () => {
            OccDetails.browseHierarchy(this.element.dataset.id, this.span.innerHTML)
            this.hideBranches()
            if (!document.getElementById(this.parent).classList.contains('subcatcurrent')) {
              this.hideBranches()
              document.getElementById(this.parent).classList.add('subcatcurrent')
              document.getElementById(this.parent.treeNode.subcatnode).classList.add('parent')
              Search.setCategory(this.element.id, this.span.innerHTML)
            }
          }

          EventHandler.on(this.span, EVENT_CLICK, this.clickProduct)
        },
        onDispose() {
          EventHandler.off(this.span, EVENT_CLICK, this.clickProduct)
        }
      }
    }

    if (!this.types._root) {
      this.types._root = {}
    }

    for (const type in this.types) {
      if ({}.hasOwnProperty.call(this.types, type)) {
        /* Group: Options
            All options are unique per type, and can be accessed inside class functions by "this.options.<option>". */
        const options = {
          /* Option: className
              The className for the newly created element div and span elements, defaults to the node type */
          className: type,
          /* Option: draggable
              Not yet implimented. */
          draggable: false,
          /* Option: leafNode
              If true, the <mark> will get the className 'leaf' and clicks will not fire a <toggleChildren>. */
          leafNode: false,
          /* Option: page
              If specified, <getContents> will be called on clicking the <mark>. */
          page: null,
          /* Option: prependParentId
              If not false, the new element id will be prepended with it's parent's id and this option's value as a separator.

              Example::
              |prependParentId: '_', parent.id: 'one-4', id: 'two-3'
              |newid = 'one-4_two-3' */
          prependParentId: false,
          /* Option: insertion
              The insertion function used to handle the node "data".

              See <Ajax.Tree.Base.insertion> */
          insertion: Element.update
        }
        this.types[type] = {
          ...options,
          ...this.types[type]
        }
      }
    }

    this.options = this.types[this.type]
    this.children = []
    this.loaded = this.opening = this.root = false

    /* create special purpose root node if parent == null */
    if (parent === null) {
      this.element = document.getElementById(id.id)
      if (this.element) {
        this.element.classList.add.apply(this.element.classList, this.type.split(' '))
        this.element.id = id.id
        this.element.treeNode = this
        this.parent = this.element.parentNode || document.body
        this.root = true
        if (data) {
          if (this.options) {
            // this.options.insertion.call(this, this.element,data.data || data);
            this.element.setInnerHTML(data.data || data)
          }

          if (data.nodes) {
            this.createNodes(data.nodes)
          }
        }
      }

      return
    }

    this.parent = (parent && parent.nodeType ? parent : document.getElementById(parent))
    this.id = id
    // read lable_long from data-array for title-tag (bf: 21.9.09)
    this.labelLong = data.label_long || ''
    this.createNode()
    // this.options.insertion.call(this, this.span, data.data || data);

    const myNodeId = (String(this.id).includes(',')) ? this.id.slice(0, Math.max(0, this.id.indexOf(','))) : this.id

    // (String(this.id).includes(',')) ? this.id.slice(0, -1) : this.id
    this.span.dataset.nodeid = myNodeId
    if (String(this.id).includes(',')) {
      const myPath = String(this.id).split(',')
      this.span.dataset.nodeid = myPath.pop()
      this.span.dataset.path = myPath.join(',')
    } else {
      this.span.dataset.path = ''
    }

    this.span.setInnerHTML(data.data || data)

    /* if this node's parent doesn't have a tree node, create a special purpose one */
    if (!this.parent.treeNode) {
      const newNode = new this.constructor(null, this.parent)
      // this.parent.treeNode = newNode
    }

    this.parent.treeNode.children.push(this)
    if (data.nodes) {
      this.createNodes(data.nodes)
    }
    /*
    const newTreeClass = Class.create()
    newTreeClass.prototype = {
      ...defaultConfirmOptions,
      ...arguments[1]
    }
    Object.extend(newTreeClass.prototype, Object.extend(Ajax.Tree.Base.prototype, structure))
    newTreeClass.prototype.constructor = newTreeClass
    return newTreeClass
    */
  }

  clearContents() {
    while (this.children.length) {
      const node = this.children.shift()
      node.dispose()
    }

    this.loaded = false
    this.hideChildren()
    if (this.options.onClearContents) {
      this.options.onClearContents.call(this)
    }
  }

  createNode() {
    // only make nodes collapsable if not root (bf: 6.7.09)
    let linkType = (this.options.leafNode ? 'leaf' : 'collapsed')
    linkType = (this.parent.treeNode === null ? 'rootleaf' : linkType)
    const newID = (this.options.prependParentId !== false ? this.parent.id + this.options.prependParentId : '') + this.id
    this.span = document.createElement('span')
    this.span.className = this.options.className + ' atv_treedata'
    if (this.options.className == 'category') {
      this.parent.dataset.item = String(this.labelLong).replace(' ', '_')
    }
    // no hover-effect for root-node (bf: 6.7.09)
    if (this.parent.treeNode === null) {
      this.element = document.createElement('div')
      this.element.dataset.id = newID
      this.element.id = this.options.className + '_' + String(newID).replace(',', '_')
      this.element.className = this.options.className + ' atv_treenode'
      this.element.append(this.span)
    } else {
      // otherwise: build DIV around menu-item to make it clickable and enable hover-effects (bf: 6.7.09)
      this.subcatnode = document.createElement('div')
      this.subcatnode.title = this.labelLong
      this.subcatnode.className = 'subcatnode'
      this.subcatnode.append(this.span)

      this.element = document.createElement('div')
      this.element.dataset.id = newID 
      this.element.id = this.options.className + '_' + String(newID).replace(',', '_')
      this.element.className = this.options.className + ' atv_treenode'
      this.element.append(this.subcatnode)
    }

    // this.events.observe(this.span, 'click', this.onClick.bindAsEventListener(this));
    if (this.parent.treeNode !== null) {
      // add click-event to the whole item (DIV) if item is not root (bf: 6.7.09)
      EventHandler.on(this.subcatnode, EVENT_CLICK, ev => this.onClick(ev))
    } else {
      EventHandler.on(this.element, EVENT_CLICK, ev => this.onClick(ev))
    }

    this.element.treeNode = this
    this.parent.append(this.element)
  }

  createNodes(nodes) {
    this.showChildren()
    this.loaded = true
    for (let i = 0; i < nodes.length; i++) {
      const newNode = new this.constructor(this.element, nodes[i].id, nodes[i].type, nodes[i])
    }

    if (nodes.length && this.options.sortable) {
      this.createSortable()
    }
  }

  createSortable() {
    // if(!this.options.sortable) return;
    Sortable.create(this.element, {
      tag: 'div',
      only: 'atv_treenode'
    })
  }

  deleteChildNode(node) {
    this.children = this.children.without(node)
    node.dispose()
  }

  deleteSelf() {
    if (this.parent.treeNode) {
      this.parent.treeNode.deleteChildNode(this)
    } else {
      this.dispose()
    }

    if (this.options.onDeleteSelf) {
      this.options.onDeleteSelf.call(this)
    }
  }

  dispose() {
    // if(this.options.sortable){ Sortable.destroy(this.sortable); }
    this.clearContents()
    if (this.options.dispose) {
      this.options.dispose.call(this)
    }

    if (this.disposables) {
      while (this.disposables.length) {
        this.disposables.shift().dispose()
      }
    }

    this.element.treeNode = null
    this.element.remove()
  }

  getContents(onSuccess) {
    if (this.opening || !this.options.page) {
      return
    }

    this.opening = true
    const params = {
      action: 'getContents',
      occasion_id: ff.occasion_id,
      ...((this.options.callback) ? this.options.callback.call(this, this.element.id) : { id: this.element.id })
    }
    const request = api.get(
      this.options.page,
      params,
      true
    ).then(json => {
      this.opening = false
      if (this.options.onGetContentsComplete) {
        this.options.onGetContentsComplete.call(this, xhr, json)
      }

      return json
    })
    .then(json => {
      let data = {}
      try {
        const o = JSON.parse(json)
        if (o && typeof o === 'object') {
          data = o
        }
      } catch (_) {
        data = json
      }

      this.clearContents()
      this.showChildren()
      this.loadContents(data, json)
      if (onSuccess) {
        onSuccess()
      }
    })
    if (this.options.onGetContents) {
      this.options.onGetContents.call(this, request)
    }
  }

  hide(el) {
    //Object.assign(el.style, { visibility: 'hidden', display: 'none' })
    //el.hide()
  }

  hideChildren() {
    /*
    for (const el of this.children) {
      this.hide(el.element)
    }
    */
  }

  loadContents(data, json) {
    if (this.options.onLoadContent) {
      this.options.onLoadContent.call(this, data, json)
    }

    if (data.nodes) {
      this.createNodes(data.nodes)
      if (this.options.onContentLoaded) {
        this.options.onContentLoaded.call(this, data, json)
      }
    }
  }

  onClick(event) {
    if (this.options.onClick && this.options.onClick.call(this, event) === false) {
      return
    }

    // this.clicked = this.element.id

    // event.stopPropagation()
    // unmark all nodes before marking the new active one (bf: 6.7.09)
    //this.removeAllCurrentSubcatnodes()
    /*
    const oldCurrents = document.querySelectorAll('.current')
    for (const el of oldCurrents) {
      el.classList.remove('current')
    }
    */
    if (this.options.page) {
      this.removeAllCurrentSubcatnodes()
      if (this.subcatnode) {
        if (this.subcatnode.parentElement.classList.contains('curparent')) {
          const thisParents = SelectorEngine.parents(this.subcatnode,
            'div.category, div.subcategory')
          for (const el of thisParents) {
            SelectorEngine.findOne('.subcatnode', el).classList.add('current')
          }
        } else {
          this.subcatnode.classList.add('current')
        }
      }

      if (this.loaded) {
        this.clearContents()
        // remove white background and change arrow on fold (bf: 25.8.09)
        document.getElementById(this.element.id).classList.remove('subcatcurrent')
        for (const el of document.querySelectorAll('#ajaxTreeView div.subcatnode.parent')) {
          el.classList.remove('parent')
        }
      } else {
        // change background-color on active node (bf: 6.7.09)
        if (this.subcatnode) {
          this.subcatnode.classList.add('current')
        }

        this.getContents()
      }

      const oldParents = document.querySelectorAll('.curparent')
      for (const el of oldParents) {
        el.classList.remove('curparent')
      }

      const thisParents = SelectorEngine.parents(this.subcatnode,
        'div[id^=ajaxTreeView], div[id^=category_ajaxTreeView], div.subcategory')
      for (const el of thisParents) {
        el.classList.add('curparent')
      }
    } else if (!this.options.leafNode) {
      // change background-color on active node (bf: 6.7.09) // mainlevel

      const oldParents = document.querySelectorAll('.curparent')
      for (const el of oldParents) {
        el.classList.remove('curparent')
      }

      let thisParents = {}

      if (this.subcatnode) {
        if (this.element.classList.contains('category') && this.subcatnode.classList.contains('current')) {
          this.subcatnode.classList.remove('current')
          thisParents = SelectorEngine.parents(this.element,
            'div[id^=ajaxTreeView], div[id^=category_ajaxTreeView], div.subcategory')
          for (const el of thisParents) {
            el.classList.remove('curparent')
          }
        } else {
          this.subcatnode.classList.add('current')
          thisParents = SelectorEngine.parents(this.subcatnode,
            'div[id^=ajaxTreeView], div[id^=category_ajaxTreeView], div.subcategory')
          for (const el of thisParents) {
            el.classList.add('curparent')
          }
        }
      }
      this.toggleChildren()
    } else if (this.options.leafNode) {
      this.removeAllCurrentSubcatnodes()
      // change background-color on active node (bf: 6.7.09)
      this.subcatnode.classList.add('current')
      OccDetails.browseHierarchy(this.element.dataset.id, this.span.innerHTML)
      this.hideBranches()
      if (!this.parent.classList.contains('subcatcurrent')) {
        this.hideBranches()
        this.parent.classList.add('subcatcurrent')
        this.parent.treeNode.subcatnode.classList.add('parent')
        const thisParents = SelectorEngine.parents(this.subcatnode,
          'div[id^=ajaxTreeView], div[id^=category_ajaxTreeView], div.subcategory')
        for (const el of thisParents) {
          el.classList.add('curparent')
        }

        Search.setCategory(this.element.dataset.id, this.span.innerHTML)
      }
    }
  }

  show(el) {
    //Object.assign(el.style, { visibility: 'visible', display: 'block' })
    //el.show()
  }

  showChildren() {
    for (const el of this.children) {
      //this.show(el.element)

      if (isMobile) {
        const subChildren = el.element.querySelectorAll('.current')
        /*
        for (const ch of subChildren) {
          this.show(ch)
        }
        */
      }
    }
  }

  toggle() {
    //this.element.visible() ? this.hide() : this.show()
  }

  toggleChildren() {
    this.isExpanded() ? this.hideChildren() : this.showChildren()
  }

  isExpanded() {
    return this.element.classList.contains('current')
    // || this.subcatnode.classList.contains('current'))
  }

  // new function to unmark all previous nodes after choosing a new one (bf: 6.7.09)
  removeAllCurrentSubcatnodes() {
    for (const el of document.querySelectorAll('#ajaxTreeView div.subcatnode.current')) {
      el.classList.remove('current')
      //el.classList.add('parent')
    }
  }

  createSublevel(thisid, thisnodes) {
    new this.constructor(this.element, thisid, 'subcategory', thisnodes)
  }

  hideBranches() {
    const myPath = this.element.dataset.id.split(',')
    const myNodeId = (!this.element.dataset.id.includes(',')) ? this.element.dataset.id : this.element.dataset.id.slice(0, Math.max(0, this.element.dataset.id.indexOf(',')))
    let myRoot = this.parent.treeNode
    for (let i = 0; i < (myPath.length - 1); i++) {
      if (document.getElementById(myPath[i])) {
        document.getElementById(myPath[i]).classList.remove('subcatcurrent')
      }
    }

    while (myRoot.root === false) {
      myRoot = myRoot.parent.treeNode
    }

    for (const e of document.querySelectorAll('#ajaxTreeView div.subcatnode.parent')) {
      e.classList.remove('parent')
    }

    for (const rootNode of ff.treeRoots) {
      if (rootNode.children[0].element.dataset.id != myRoot.children[0].children[0].element.dataset.id) {
        for (let child = 0; child <= rootNode.children.length - 1; child++) {
          if (rootNode.children[child].element) {
            rootNode.children[child].element.classList.remove('subcatcurrent')
          }

          rootNode.children[child].clearContents()
        }
      }
    }

    this.foldMyRoot(myRoot.children[0], 0, myPath)
  }

  foldMyRoot(item, nodeCount, myPath) {
    let myPathHere = ''
    for (let path = 0; path <= nodeCount; path++) {
      myPathHere += (myPathHere == '') ? myPath[path] : ',' + myPath[path]
    }

    for (let child = 0; child <= item.children.length - 1; child++) {
      if (item.children[child].length > 0 && item.children[child].element.dataset.id != myPathHere) {
        if (item.children[child].element.length > 0) {
          //document.getElementById(item.children[child].element.id).classList.remove('subcatcurrent')
          item.children[child].element.classList.remove('subcatcurrent')
        } else {
          console.log('can\'t find ' + item.children[child].element.id)
        }

        item.children[child].clearContents()
      }

      this.foldMyRoot(item.children[child], nodeCount + 1, myPath)
    }
  }
}

export default ProductTree
